var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.visible)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog bs_100",style:({
      'background-image': 'url(' + _vm.imgInfo[_vm.type].bg_img + ')',
      width: _vm.imgInfo[_vm.type].width,
      height: _vm.imgInfo[_vm.type].height,
      marginTop: _vm.imgInfo[_vm.type].top || '',
    })},[_c('img',{class:("dialog-close_" + (_vm.imgInfo[_vm.type].closeType)),attrs:{"src":require("@/assets/pc/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.close}}),_c('img',{staticClass:"dialog-title",style:({
        height: _vm.imgInfo[_vm.type].title_height,
        marginBottom: _vm.imgInfo[_vm.type].title_mb || '',
        marginTop: _vm.imgInfo[_vm.type].title_mt || '',
      }),attrs:{"src":_vm.imgInfo[_vm.type].title_img,"alt":""}}),(_vm.imgInfo[_vm.type].content)?_c('div',{staticClass:"dialog-content"},_vm._l((_vm.transformContent(_vm.imgInfo[_vm.type].content)),function(item,index){return _c('p',{key:index,style:({
          textAlign: _vm.imgInfo[_vm.type].textAlign,
        }),domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e(),(_vm.type === 'type5')?_c('div',{staticClass:"dialog-reward"},[_c('div',{staticClass:"reward_bg bs_contain",style:({
          'background-image':
            'url(' +
            require('@/assets/mobile/popup/popup_bg12_texiao.png') +
            ')',
        })},[_c('div',{staticClass:"bs_contain reward_img",style:({
            'background-image': 'url(' + _vm.reward.prize_show_img + ')',
          })}),_c('span',[_vm._v(_vm._s(_vm.reward.name))])])]):_vm._e(),(_vm.imgInfo[_vm.type].btn_img)?_c('img',{staticClass:"dialog-btn",style:({ marginTop: _vm.type === 'type5' ? '-1.5rem' : '' }),attrs:{"src":_vm.imgInfo[_vm.type].btn_img,"alt":""},on:{"click":_vm.handelConfirm}}):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }