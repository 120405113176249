<template>
  <div
    class="home"
    :style="{
      'background-image': 'url(' + imgInfo.bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    }"
  >
    <div
      class="content"
      :style="{
        'background-image': 'url(' + imgInfo.info_list[cur_act_index].bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': cur_act_type_index
          ? 'center center'
          : 'center bottom',
      }"
    >
      <div class="content_left">
        <div
          class="top_arrow bs_contain"
          :style="{
            'background-image':
              'url(' + require('@/assets/pc/character/bg_button1.png') + ')',
          }"
          @click="handelViewCharacter('prev')"
        ></div>
        <div
          class="top_head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[0].head_def + ')',
          }"
          @click="handelViewCharacter('prev')"
        ></div>
        <div
          class="center_line bs_contain"
          :style="{
            'background-image':
              'url(' +
              require('@/assets/pc/character/bg_xuanxiangbian.png') +
              ')',
          }"
        ></div>
        <div
          class="center_head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[1].head_hover + ')',
          }"
        ></div>
        <div
          class="bot_arrow bs_contain"
          :style="{
            'background-image':
              'url(' + require('@/assets/pc/character/bg_button2.png') + ')',
          }"
          @click="handelViewCharacter('next')"
        ></div>
        <div
          class="bot_head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[2].head_def + ')',
          }"
          @click="handelViewCharacter('next')"
        ></div>
      </div>
      <div class="content_bottom">
        <img
          class="l_arrow"
          @click="handelChangeType"
          src="@/assets/pc/character/bg_arrow1.png"
          alt=""
          :style="{ top: cur_act_type_index ? '5%' : '10%' }"
        />
        <div
          class="c_img bs_contain"
          :style="{
            'background-image':
              'url(' + imgInfo.switch_type_imgs[cur_act_type_index] + ')',
          }"
        ></div>
        <img
          class="r_arrow"
          src="@/assets/pc/character/bg_arrow1_2.png"
          alt=""
          @click="handelChangeType"
          :style="{ bottom: cur_act_type_index ? '15%' : '10%' }"
        />
      </div>
      <div class="content_right">
        <div class="title">
          <span class="title_text">{{
            imgInfo.info_list[cur_act_index].name
          }}</span>
          <img
            :src="
              cur_act_index < 5
                ? require('@/assets/pc/character/bp_icon2.png')
                : require('@/assets/pc/character/bp_icon1.png')
            "
            alt=""
          />
        </div>
        <span class="skill">{{ imgInfo.info_list[cur_act_index].tag }}</span>
        <div class="type">
          <div class="type_left">
            <img src="@/assets/pc/character/bp_title_xiyoudu.png" alt="" />
            <span>{{ imgInfo.info_list[cur_act_index].level }}</span>
          </div>
          <div class="type_right">
            <img src="@/assets/pc/character/bp_title_leixing.png" alt="" />
            <span>{{ imgInfo.info_list[cur_act_index].type }} </span>
          </div>
        </div>
        <div class="intro">
          <span>
            {{ imgInfo.info_list[cur_act_index].intro }}
          </span>
        </div>
      </div>
      <div class="content_shadow">
        <img src="@/assets/pc/character/bg_yinying.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {},
  name: "component_name",
  data() {
    return {
      cur_act_type_index: 0, // 类型0：异兽，1：巨兽
      cur_act_index: 0,
    };
  },
  created() {},
  computed: {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        JA: {
          bg: require("@/assets/pc/character/bg.png"),
          info_list:
            this.cur_act_type_index === 0
              ? [
                  // ------巨兽
                  {
                    name: this.$t("role1.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_wanlong.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_wanlong.png"),
                    bg: require("@/assets/pc/character/character_wanlong.png"),
                    level: this.$t("role1.level"),
                    type: this.$t("role1.type"),
                    intro: this.$t("role1.intro"),
                    tag: this.countTagText(this.$t("role1.tag")),
                  },
                  {
                    name: this.$t("role2.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_niulong.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_niulong.png"),
                    bg: require("@/assets/pc/character/character_niulong.png"),
                    level: this.$t("role2.level"),
                    type: this.$t("role2.type"),
                    intro: this.$t("role2.intro"),
                    tag: this.countTagText(this.$t("role2.tag")),
                  },
                  {
                    name: this.$t("role3.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_jilong.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_jilong.png"),
                    bg: require("@/assets/pc/character/character_jilong.png"),
                    level: this.$t("role3.level"),
                    type: this.$t("role3.type"),
                    intro: this.$t("role3.intro"),
                    tag: this.countTagText(this.$t("role3.tag")),
                  },
                  {
                    name: this.$t("role4.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_jialong.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_jialong.png"),
                    bg: require("@/assets/pc/character/character_jialong.png"),
                    level: this.$t("role4.level"),
                    type: this.$t("role4.type"),
                    intro: this.$t("role4.intro"),
                    tag: this.countTagText(this.$t("role4.tag")),
                  },
                  {
                    name: this.$t("role5.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_bawanglong.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_bawanglong.png"),
                    bg: require("@/assets/pc/character/character_bawanglong.png"),
                    level: this.$t("role5.level"),
                    type: this.$t("role5.type"),
                    intro: this.$t("role5.intro"),
                    tag: this.countTagText(this.$t("role5.tag")),
                  },
                ]
              : [
                  // ------异兽
                  {
                    name: this.$t("role6.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_yema.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_yema.png"),
                    bg: require("@/assets/pc/character/character_yema.png"),
                    level: this.$t("role6.level"),
                    type: this.$t("role6.type"),
                    intro: this.$t("role6.intro"),
                    tag: this.countTagText(this.$t("role6.tag")),
                  },
                  {
                    name: this.$t("role7.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_xiaoxiongmao.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_xiaoxiongmao.png"),
                    bg: require("@/assets/pc/character/character_xiaoxiongmao.png"),
                    level: this.$t("role7.level"),
                    type: this.$t("role7.type"),
                    intro: this.$t("role7.intro"),
                    tag: this.countTagText(this.$t("role7.tag")),
                  },
                  {
                    name: this.$t("role8.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_lanhou.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_lanhou.png"),
                    bg: require("@/assets/pc/character/character_lanhou.png"),
                    level: this.$t("role8.level"),
                    type: this.$t("role8.type"),
                    intro: this.$t("role8.intro"),
                    tag: this.countTagText(this.$t("role8.tag")),
                  },
                  {
                    name: this.$t("role9.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_laohu.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_laohu.png"),
                    bg: require("@/assets/pc/character/character_laohu.png"),
                    level: this.$t("role9.level"),
                    type: this.$t("role9.type"),
                    intro: this.$t("role9.intro"),
                    tag: this.countTagText(this.$t("role9.tag")),
                  },
                  {
                    name: this.$t("role10.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_baihu.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_baihu.png"),
                    bg: require("@/assets/pc/character/character_baihu.png"),
                    level: this.$t("role10.level"),
                    type: this.$t("role10.type"),
                    intro: this.$t("role10.intro"),
                    tag: this.countTagText(this.$t("role10.tag")),
                  },
                  {
                    name: this.$t("role11.name"),
                    head_def: require("@/assets/pc/character/icon_unselected_shizi.png"),
                    head_hover: require("@/assets/pc/character/icon_highlight_shizi.png"),
                    bg: require("@/assets/pc/character/character_shizi.png"),
                    level: this.$t("role11.level"),
                    type: this.$t("role11.type"),
                    intro: this.$t("role11.intro"),
                    tag: this.countTagText(this.$t("role11.tag")),
                  },
                ],
          switch_type_imgs: [
            require("@/assets/pc/character/bg_title_2.png"),
            require("@/assets/pc/character/bg_title_1.png"),
          ],
        },
      };
      return imgObj[this.region];
    },
    currentShowHead() {
      let list = cloneDeep(this.imgInfo.info_list);

      return this.getItemsAroundIndex(list, this.cur_act_index);
    },
  },
  mounted() {},

  methods: {
    handelChangeType() {
      this.cur_act_index = 0;
      this.cur_act_type_index = (this.cur_act_type_index + 1) % 2;
    },
    countTagText(v) {
      return v.join("/");
    },
    getItemsAroundIndex(array, index) {
      const arrayLength = array.length;

      // 确保数组不为空且索引在数组范围内
      if (arrayLength === 0) return [];
      index = ((index % arrayLength) + arrayLength) % arrayLength; // 处理负索引

      const prevIndex = (index - 1 + arrayLength) % arrayLength;
      const nextIndex = (index + 1) % arrayLength;

      return [array[prevIndex], array[index], array[nextIndex]];
    },
    handelViewCharacter(t) {
      if (t === "next") {
        if (this.cur_act_index !== this.imgInfo.info_list.length - 1) {
          this.cur_act_index++;
        } else {
          this.cur_act_index = 0;
        }
      } else if (t === "prev") {
        if (this.cur_act_index !== 0) {
          this.cur_act_index--;
        } else {
          this.cur_act_index = this.imgInfo.info_list.length - 1;
        }
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  // background-color: rgb(9, 14, 10);
  .content {
    width: 100%;
    height: calc(100% - 5.56rem);
    margin-top: 5.56rem;
    position: relative;
    &_left {
      width: 14.2%;
      height: 70.55%;
      position: absolute;
      z-index: 999;
      left: 2.5%;
      top: 12%;
      .top_arrow {
        width: 14.19rem;
        height: 9rem;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
      }
      .top_head {
        width: 9.75rem;
        height: 9.75rem;
        position: absolute;
        right: 21%;
        top: 9%;
        z-index: 99;
        cursor: pointer;
      }
      .center_line {
        width: 100%;
        height: 75%;
        position: absolute;
        top: 6%;
        left: 6%;
      }
      .center_head {
        width: 9.75rem;
        height: 9.75rem;
        position: absolute;
        right: 35%;
        top: 37%;
        z-index: 99;
      }
      .bot_arrow {
        width: 14.19rem;
        height: 9rem;
        position: absolute;
        right: 0;
        top: 76%;
        cursor: pointer;
      }
      .bot_head {
        width: 9.75rem;
        height: 9.75rem;
        position: absolute;
        right: 21%;
        top: 64%;
        z-index: 99;
        cursor: pointer;
      }
    }
    &_bottom {
      // width: 23rem;
      height: 5rem;
      position: absolute;
      bottom: 5.2%;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 99;
      display: flex;
      justify-content: center;
      align-items: center;
      .c_img {
        width: 20rem;
        height: 5rem;
      }
      img {
        width: 2rem;
        height: 2.8rem;
        cursor: pointer;
        &:hover {
          transform: scale(1.2);
        }
      }
    }
    &_right {
      width: 32%;
      // height: auto;
      height: 20rem;
      position: absolute;
      top: 30.85%;
      right: 2.7%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .title {
        width: 100%;
        height: 4.53rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        &_text {
          height: 3.5rem;
          line-height: 3.5rem;
          font-size: 3.91rem;
          font-family: SourceHanSerifSC;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0.1rem 0rem 3rem #2c3364;
          background: linear-gradient(0deg, #e0cfa5 20%, #fef4dc 60%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        img {
          width: 3.91rem;
          height: 4.53rem;
          margin-left: 0.7rem;
        }
      }
      .skill {
        height: 1.75rem;
        line-height: 1.75rem;
        font-size: 1.64rem;
        font-family: SourceHanSerifSC;
        font-weight: 500;
        color: #ffffff;

        background: linear-gradient(0deg, #e0cfa5 40%, #fef4dc 60%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 1.3rem 0;
      }
      .type {
        width: calc(100% - 11.8rem);
        height: 3.3rem;
        padding: 0 5.4rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_left {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          img {
            height: 100%;
          }
          span {
            font-size: 1.64rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #192521;

            background: linear-gradient(0deg, #e0cfa5 40%, #fef4dc 60%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 1.06rem;
          }
        }
        &_right {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          img {
            height: 100%;
          }
          span {
            font-size: 1.64rem;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #192521;

            background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-left: 1.06rem;
          }
        }
      }
      .intro {
        width: 100%;
        height: auto;
        padding: 1.3rem 2.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("~@/assets/pc/character/bp_bg.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        margin-top: 2.56rem;
        position: relative;
        &::before {
          width: 100%;
          height: 0.6rem;
          position: absolute;
          top: -0.3rem;
          left: 0;
          content: "";

          background-image: url("~@/assets/pc/character/bp_line.png");
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        &::after {
          width: 100%;
          height: 0.6rem;
          content: "";
          position: absolute;
          bottom: -0.3rem;
          left: 0;
          background-image: url("~@/assets/pc/character/bp_line.png");
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
        }
        span {
          font-size: 1.18rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 2rem;
          width: 82%;
          text-align: left;
        }
      }
    }
    &_shadow {
      width: 100%;
      position: absolute;
      bottom: -1%;
      left: 0;
      z-index: 9;

      img {
        width: 100%;
      }
    }
  }
}
</style>
