<template>
  <div class="invite">
    <!-- 显示当前帧的图片 -->
    <img v-if="currentFrame" :src="currentFrame" alt="Animation Frame" />
  </div>
</template>
    
  <script>
import { mapMutations, mapState } from "vuex";

const imageCache = {};
export default {
  data() {
    return {
      frames: [], // 存储序列帧的数组
      currentIndex: 0, // 当前显示的帧索引
      //   frameRate: 15, // 每秒帧数
    };
  },
  computed: {
    ...mapState(["msg", "region", "user_info", "region_type", "mobile"]),

    currentFrame() {
      // 得到当前索引对应的帧图片路径
      return imageCache[this.currentIndex]?.src
        ? imageCache[this.currentIndex]?.src
        : "";
    },
    frameRate() {
      return this.mobile ? 13 : 15;
    },
  },
  methods: {
    startAnimation() {
      const frameCount = this.frames.length;
      const delay = 1000 / this.frameRate;

      // 定义一个循环播放序列帧的函数
      const loop = () => {
        this.currentIndex = (this.currentIndex + 1) % frameCount; // 更新索引
        this.animationFrameId = setTimeout(loop, delay); // 设置下一帧的超时
      };

      this.animationFrameId = setTimeout(loop, delay);
    },
    stopAnimation() {
      clearTimeout(this.animationFrameId); // 停止动画
    },
    preloadImages(urls) {
      return new Promise((resolve, reject) => {
        urls.forEach((url, index) => {
          if (!imageCache[url]) {
            const img = new Image();
            img.onload = () => {
              imageCache[index] = img;

              // 检查是否所有图片都被加载

              if (Object.keys(imageCache).length === urls.length) {
                // 执行全部加载完成后的操作
                resolve();
              }
            };
            img.src = url;
          }
        });
      });
    },
  },
  async mounted() {
    // 初始化序列帧数组
    this.frames = this.mobile
      ? [
          require("@/assets/invite_effect/mobile/tx-_0000s_0000_1.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0001_2.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0002_3.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0003_4.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0004_5.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0005_6.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0006_7.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0007_8.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0008_9.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0009_10.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0010_11.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0011_12.png"),
          require("@/assets/invite_effect/mobile/tx-_0000s_0012_13.png"),
        ]
      : [
          require("@/assets/invite_effect/pc/texiao-_0000s_0000_1.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0001_2.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0002_3.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0003_4.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0004_5.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0005_6.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0006_7.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0007_8.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0008_9.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0009_10.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0010_11.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0011_12.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0012_13.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0013_14.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0014_15.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0015_16.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0016_17.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0017_18.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0018_19.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0019_20.png"),
          require("@/assets/invite_effect/pc/texiao-_0000s_0020_21.png"),
        ];
    await this.preloadImages(this.frames);
    this.startAnimation();

    // 开始动画
  },
  beforeDestroy() {
    // 组件销毁前停止动画

    this.stopAnimation();
  },
};
</script>
  <style lang="scss" scoped>
.invite {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>