<template>
  <div class="dialog-overlay" v-if="visible">
    <div
      class="dialog bs_100"
      :style="{
        'background-image': 'url(' + imgInfo[type].bg_img + ')',
        width: imgInfo[type].width,
        height: imgInfo[type].height,
        marginTop: imgInfo[type].top || '',
      }"
    >
      <img
        :class="`dialog-close_${imgInfo[type].closeType}`"
        src="@/assets/pc/popup/popup_closebutton.png"
        alt=""
        @click="close"
      />
      <img
        class="dialog-title"
        :style="{
          height: imgInfo[type].title_height,
          marginBottom: imgInfo[type].title_mb || '',
          marginTop: imgInfo[type].title_mt || '',
        }"
        :src="imgInfo[type].title_img"
        alt=""
      />
      <div class="dialog-content" v-if="imgInfo[type].content">
        <p
          v-for="(item, index) in transformContent(imgInfo[type].content)"
          :key="index"
          :style="{
            textAlign: imgInfo[type].textAlign,
          }"
          v-html="item"
        ></p>
      </div>
      <div class="dialog-reward" v-if="type === 'type5'">
        <div
          class="reward_bg bs_contain"
          :style="{
            'background-image':
              'url(' +
              require('@/assets/mobile/popup/popup_bg12_texiao.png') +
              ')',
          }"
        >
          <div
            class="bs_contain reward_img"
            :style="{
              'background-image': 'url(' + reward.prize_show_img + ')',
            }"
          ></div>

          <span>{{ reward.name }}</span>
        </div>
      </div>
      <img
        v-if="imgInfo[type].btn_img"
        class="dialog-btn"
        :src="imgInfo[type].btn_img"
        alt=""
        @click="handelConfirm"
        :style="{ marginTop: type === 'type5' ? '-1.5rem' : '' }"
      />
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    type: {
      type: String,
      default: "type1",
    },
    onClose: Function,
    onConfirm: Function,

    reward: {
      type: Object,
      default: () => {},
    },
    // 剩余次数
    residueDegree: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    // ...mapState(["msg", "region", "swiperEnable"]),

    imgInfo() {
      const imgObj = {
        JA: {
          // 次数不足
          type1: {
            title_img: require("@/assets/mobile/popup/popup_title5.png"),
            title_height: "5rem",
            bg_img: require("@/assets/mobile/popup/popup_bg4.png"),
            width: "67.7%",
            height: "27rem",
            textAlign: "center",
            content: `ガチャを引く回数が足りません。
              タスクを完了して回数を獲得した後に操作してください。`,
            btn_img: require("@/assets/mobile/popup/popup_button_normal_ok.png"),
            closeType: 2,
          },
          // 预约进度说明
          type2: {
            title_img: require("@/assets/mobile/popup/popup_title7.png"),
            title_height: "6rem",
            title_mb: "0",
            title_mt: "-1.5rem",
            bg_img: require("@/assets/mobile/popup/popup_bg4.png"),
            width: "80%",
            height: "35rem",
            content: `イベント期間：2024年4月18日からゲームリリース日まで
              <div style='height:10px'>
              </div>
              ルール：
              予約件数は目標に達すると、その段階の報酬を獲得できます。報酬はゲームリリース後、ゲーム内のイベント画面で報酬を受け取れます。この期間中にログインしない場合は受け取れませんのでご注意ください。
              ※事前登録者数に応じてプレゼントする特典およびその名称は予告なく変更する場合がございます。予めご了承ください。`,
            closeType: 2,
          },
          // 活动规则说明
          type3: {
            title_img: require("@/assets/mobile/popup/popup_title2.png"),
            bg_img: require("@/assets/mobile/popup/popup_bg3.png"),
            width: "91.7%",
            height: "70rem",
            title_height: "6rem",
            top: "3rem",
            textAlign: "left",
            content: `キャンペーン期間：
                      2024年4月18日からゲームリリース日まで
                      ガチャルール：
                      1.本ページでメール予約を完了すると、ガチャキャンペーンに参加できます。
                      2.ガチャ回数の獲得方法：
                      A.メール予約した後、ガチャを1回引くことができます；
                      B.公式アカウント（任意）をフォローすると、ガチャを1回引くことができます；
                      C.専用リンクを介して友達を招待し、その友達がメール予約を完了すると、あなたはガチャを1回引くことができます。最大3人の友達を招待して、ガチャを3回引くことができます。
                      3.各プレーヤーは最大で5回のガチャ回数を獲得できます。
                      <div style='height:10px'>
                      </div>
                      報酬の配布：
                      1.当選した際には、該当する報酬のコードを入手できます。報酬のコードは「当選照会」で確認できます。
                      2.ゲーム内アイテム報酬を獲得したプレーヤーは、ゲームがリリース（初回ダウンロードと登録済み）された後、ゲームの公式サイトで「コード」と「ユーザーID」を使用して報酬を受け取ることができます（新人ガイド完了後、アイコンをタップして「ユーザーID 」を取得できます）。報酬は対応するゲームアカウントに配布されます（ゲーム内アイテム報酬の受け取り期限は2024年12月31日までであり、期限を超えると自動的に報酬を放棄したものと見なされます）。
                      3.現物報酬を当選したプレーヤーは、ゲームがリリースされた後（出勤日10日間内）、ゲーム内で「コード」と「予約メール」をオフィシャルサポーターに提供して受け取ります。期限を超えると自動的に報酬を放棄したものと見なされます（個人の「コード」と「予約メール」情報を大切に管理してください。個人情報の漏洩により他者が現物報酬を受け取った場合、オフィシャルサポーターはそのほかの補償を行いません）。
                      4.キャンペーンの現物報酬は現金や同等の商品に交換することができません。
                      5.キャンペーンの現物報酬の配布範囲は日本地域のプレーヤーに限られています。自身の所在地域に基づいてキャンペーンに参加してください。
                      6.もしプレーヤーが外部ツール、スクリプト、チートなど不正な手段でこのキャンペーに参加した場合、オフィシャルチームはそのプレーヤーの参加資格及び報酬を取消します。
                      7.【キング・オブ・ビースト：新世界】はキャンペーンの変更権及び最終解釈権を保留します。
                      <div style='height:20px'>
                      </div>
                      友達招待のルール：
                      1.専用リンクから友達を招待し、この友達が招待リンクからメールの予約を完了すると、招待成功となります。
                      2.招待した友達の総数が1、3、5人に達すると、対応する招待進度報酬を獲得することができ、「当選照会」で対応する報酬の交換コードを確認することができます。ゲームがオンラインになった後（初回ダウンロードとログイン成功）、ゲームの公式サイトで「交換コード」と「ユーザーID」（チュートリアルを完了した後、アバターをタップして「ユーザーID」を取得する）により報酬を獲得することができ、報酬が対応するゲームアカウントに配布されます。 (ゲーム内アイテム報酬の受け取り期限は2024年12月31日までです。期限切れになり、報酬を受け取らない場合は、自動的に報酬を放棄したものとみなされます)。
                      `,
            closeType: 2,
          },
          // 邮件预约说明
          type4: {
            title_img: require("@/assets/mobile/popup/popup_title8.png"),
            title_height: "6rem",
            title_mb: "0.5rem",
            title_mt: "-1rem",
            bg_img: require("@/assets/mobile/popup/popup_bg6.png"),
            width: "80%",
            height: "30rem",
            top: "3rem",
            textAlign: "left",
            content: `イベント期間：2024年4月18日からゲームリリース日まで
                        <div style='height:10px'>
                        </div>
                        ルール：
                        1.メールアドレスを入力して予約すると、メール予約報酬を獲得できます。報酬はゲームリリース後、ゲーム内のイベント画面で報酬を受け取れます。この期間中にログインしない場合は受け取れませんのでご注意ください
                        2.各メールアドレスは1回の予約イベントにしか参加できません。正しいメールアドレスを確認して入力してください。このイベントに参加することにより、個人情報の収集・使用および報酬のSMS受信に同意したものと見なされます。プレーヤーのプライバシーを厳重に保護し、個人情報を第三者に開示いたしません。`,
            closeType: 2,
          },
          // 中奖提示
          type5: {
            title_img: require("@/assets/pc/popup/popup_title10.png"),
            title_height: "6.5rem",
            title_mb: "0.5rem",
            title_mt: "-3rem",
            bg_img: require("@/assets/pc/popup/popup_bg6.png"),
            width: "80%",
            height: "33rem",
            top: "3rem",
            textAlign: "left",
            btn_img: this.residueDegree
              ? require("@/assets/pc/popup/popup_button6_normal.png")
              : require("@/assets/pc/popup/popup_button7_normal.png"),
            closeType: 2,
          },
        },
      };
      return imgObj["JA"];
    },
    transformContent() {
      return (text) => {
        return text.split("\n");
      };
    },
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    handelConfirm() {
      switch (this.type) {
        case "type1":
          this.close();
          break;
        case "type5":
          if (this.residueDegree) {
            this.onConfirm();
          } else {
            this.close();
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
@keyframes open_mask {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/* 添加对话框的基本样式 */
.dialog-overlay {
  /* 样式省略，覆盖整个屏幕的半透明背景 */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  animation: open_mask 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.dialog {
  /* 对话框样式 */
  width: 66.7%;
  height: 72.88%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  &-close_1 {
    width: 2.3rem;
    height: 2.3rem;
    position: absolute;
    top: -1.45rem;
    right: -3.56rem;
    cursor: pointer;
  }
  &-close_2 {
    width: 2.65rem;
    height: 2.65rem;
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    cursor: pointer;
  }
  &-title {
    width: auto;
    height: 3.5rem;
    margin-bottom: 2rem;
  }
  p {
    margin: 0;
    margin: 0.4rem 0;
  }
  &-content {
    width: calc(100% + 2rem);
    height: auto;
    max-height: calc(100% - 0rem);
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 1rem;
    box-sizing: border-box;
    // padding-left: 3rem;
    padding-right: calc(1rem - 6px);
    /* 自定义滚动条的宽度和颜色 */
    &::-webkit-scrollbar {
      width: 6px; /* 滚动条宽度 */
      height: 6px; /* 水平滚动条高度 */
    }

    /* 滚动条轨道样式 */
    &::-webkit-scrollbar-track {
      background: transparent; /* 轨道颜色 */
      border-radius: 6px; /* 轨道圆角 */
    }

    /* 滚动条滑块样式 */
    &::-webkit-scrollbar-thumb {
      background: #fff; /* 滑块悬停颜色 */

      border-radius: 6px; /* 滑块圆角 */
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #fff; /* 滑块悬停颜色 */
    }
    p {
      font-size: 1.25rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
  }
  &-reward {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .reward_bg {
      width: 30rem;
      height: 25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: -4rem;

      .reward_img {
        width: 10rem;
        height: 10rem;
        margin-top: 4rem;
      }
      span {
        font-size: 1.5rem;
        color: #fff;
        margin-top: 1rem;
      }
    }
  }
  &-btn {
    width: 13.5rem;
    height: 4.5rem;
    cursor: pointer;
    margin-top: 3.25rem;
    &:hover {
      transform: scale(1.05);
    }
  }
}
</style>