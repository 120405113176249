<template>
  <div
    :class="{
      cover: !show,
      show: show,
      mask: deviceType() == 'pc',
      m_mask: deviceType() !== 'pc',
    }"
  >
    <div class="center">
      <div class="center-title"></div>
      <div class="center-close" @click="closeModal"></div>
      <div class="center-area">
        <div class="input-box">
          <input
            type="text"
            class="input"
            placeholder="E-mail"
            v-model="email"
          />
        </div>
        <div class="protocol">
          <label>
            <input
              type="checkbox"
              class="checkbox"
              name="fruits[]"
              v-model="choice"
              checked
            />
            <img
              v-if="deviceType() !== 'pc'"
              src="@/assets/mobile/register/xieyi.png"
              alt=""
            />
            <img v-else src="@/assets/pc/email/title-tp.png" alt="" />
          </label>
        </div>
        <div class="description"></div>
        <div class="register-btn" @click="handelRegister"></div>
        <div class="download-btn">
          <!-- <img src="@/assets/mobile/register/" alt=""> -->
          <div class="ios" @click="openLink('ios')"></div>
          <div class="android" @click="openLink('android')"></div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";
export default {
  name: "component_name",
  data() {
    return {
      cover: true,
      show: false,
      choice: false,
      email: "",
    };
  },
  inject: ["deviceType"],

  created() {},
  computed: {
    ...mapState(["msg", "title"]),
  },
  mounted() {},
  watch: {
    // loginVisible(val) {
    //   this.show = val;
    // },
    googleUser: {},
  },
  methods: {
    ...mapMutations(["openNewMsg"]),
    handelRegister: debounce(function () {
      const _this = this;
      const title = _this.title;
      if (!this.choice) {
        this.openNewMsg({
          title,
          body: "まずプロトコルをチェックしてください",
          autoClose: true,
          time: 1000,
        });
      } else {
        let reg =
          /^[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/;
        if (!reg.test(this.email)) {
          this.openNewMsg({
            title,
            body: "メールフォーマットエラー",
            autoClose: true,
            time: 1000,
          });
        } else {
          trackClick({
            email: this.email,
            behavior_tag: "Submit Email",
          });
          this.openNewMsg({
            title,
            body: "予約が成功しました!",
            autoClose: true,
            time: 1000,
          });
          this.show = false;
          this.choice = false;
        }
        this.email = "";
      }
    }, 600),
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Download Ios package",
        });
        window.open("https://apps.apple.com/app/id1641423103", "_blank");
      } else {
        trackClick({
          behavior_tag: "Download Android package",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.myths.jp",
          "_blank"
        );
      }
    },
    closeModal() {
      this.show = false;
      this.$emit("hideRegister", true);
      this.$emit("changeRightNav", true);
    },
    showModal() {
      this.show = true;
      this.$emit("changeRightNav", false);
    },
  },

  destroyed() {},
};
</script>
  
  <style lang="scss" scoped>
@keyframes open_mask {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.show {
  display: block;
  animation: open_mask 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.cover {
  display: none;
}

@media screen and (max-width: 849px) {
  .m_mask {
    background-image: url("../assets/mobile/register/modal.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0rem 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 1000;
    .center {
      width: 100%;
      height: 55.75rem;
      background-image: url("../assets/mobile/register/bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0rem 0;
      position: absolute;
      top: 50%;
      margin-top: -27.875rem;
      &-title {
        width: 37.58rem;
        height: 9.58rem;
        background-image: url("../assets/mobile/register/title-bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        position: absolute;
        top: -11.9rem;
        left: 50%;
        margin-left: -18.79rem;
      }
      &-close {
        width: 5.75rem;
        height: 7.33rem;
        background-image: url("../assets/mobile/register/close-btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        position: absolute;
        top: 0.75rem;
        cursor: pointer;
        right: 2.25rem;
      }
      &-area {
        width: 41.5rem;
        height: 43.67rem;
        position: absolute;
        top: 7.33rem;
        left: 50%;
        margin-left: -20.75rem;
        .input-box {
          width: 100%;
          height: 6.25rem;
          border: 1px solid;
          border-image: linear-gradient(to right, #463b24, #b3a384, #463b24) 1 1;
          position: relative;
          .input {
            position: absolute;
            top: 1.5rem;
            left: 1.2rem;
            width: calc(100% - 2.4rem);
            height: calc(100% - 3rem);
            background-color: transparent;
            border: none;
            color: #c4b8b8;
            font-size: 2.4rem;
            &::placeholder {
              color: #403a2c;
              font-size: 2.6rem;
              line-height: normal;
            }
            &:focus {
              outline: none;
            }
          }
        }
        .protocol {
          width: 100%;
          margin: 0rem 0;
          height: 4.2rem;
          margin: 1.9rem 0 5.5rem 0 !important;

          label {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
          }
          /* 外部容器 */
          .checkbox {
            position: relative;
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
          }

          /* 复选框图标 */
          .checkbox {
            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              width: 1.7rem;
              height: 1.7rem;
              background-color: #21201e;
              border: 1px solid #8b856f;
            }
          }

          /* 选中状态 */
          .checkbox:checked {
            &::before {
              background-color: #68562f;
              content: "\2713"; /* 引用 Unicode 符号 */
              color: #f5e297;
              font-size: 0.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
          img {
            width: 39rem;
            height: 4.17rem;
          }
        }
        .description {
          width: 100%;
          height: 6.7rem;
          background-image: url("~@/assets/mobile/register/xieyi2.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
        .register-btn {
          width: 100%;
          height: 9rem;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url("../assets/mobile/register/gegister-btn.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          position: absolute;
          bottom: 7.5rem;
          left: 50%;
          margin-left: -20.75rem;
          cursor: pointer;
        }
      }
      .download-btn {
        width: 100%;
        height: 6.7rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        position: absolute;
        bottom: 0rem;
        left: 50%;
        margin-left: -20.75rem;
        cursor: pointer;
        div {
          width: 20rem;
          height: 100%;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
        }
        .ios {
          background-image: url("../assets/mobile/register/ios.png");
        }
        .android {
          background-image: url("../assets/mobile/register/Android.png");
        }
      }
    }
  }
}
</style>
  