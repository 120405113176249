import Vue from "vue";
import locale from "element-ui/lib/locale";
import VueI18n from "vue-i18n";
import messages from "./lang";
import { getLanguageInfo } from "@/tools/setLanguage";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: getLanguageInfo().locale,
  messages,
});
locale.i18n((k, v) => i18n.t(k, v));
export default i18n;
