import tipsModal from "./tipsModal.vue";

const tipsDialogPlugin = {
  install(Vue) {
    const DialogConstructor = Vue.extend(tipsModal);
    let dialogInstance = null;

    // 添加实例方法
    Vue.prototype.$tipModal = function (options) {
      if (!dialogInstance) {
        dialogInstance = new DialogConstructor({
          el: document.createElement("div"),
        });
        document.body.appendChild(dialogInstance.$el);
      }

      Object.assign(dialogInstance, options);
      dialogInstance.open();

      return dialogInstance;
    };
  },
};

export default tipsDialogPlugin;
