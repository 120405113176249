import jaLocale from "element-ui/lib/locale/lang/ja";

const ja = {
  msg: {
    title: "ご注意",
    confirm: "はい",
    cancel: "いいえ",
    loading: "お待ち...",
    login: "ログインしてください",
    logout: "ログアウト",
    welcome: "ようこそ！",
    copy_success: "コピー成功",
    copy_fail: "コピーに失敗しました",
    email_regis_done: "先にメール予約をお済ませください",
    login_tips: "ご予約をお願い申し上げます",
  },
  register: {
    title1: "メール予約で豪華報酬獲得",
    tip: "-あなたのメールを入力してください-",
    agree: "個人情報の収集・利用及び報酬のご案内に同意します",
    agree1: "プライバシーポリシー",
    agree2: "利用規約",
    regis: "事前登録",
    regisResult: "報酬の表示",
    title2: "ストア予約で豪華報酬獲得",
    tip1: "あなたの報酬シリアル番号：",
    tip2: "公式アカウントをフォローしてガチャを引く回数を獲得する",
    tip3: "ストアで予約してより多くの豪華報酬を受け取ります",
    tip4: "ゲームリリース後ゲーム内のイベントで報酬を受け取れます。",
  },
  reward: {
    peopleNum: "【 事前登録者 X 件達成 】",
  },
  errMsg: {
    $10000: "サーバ内部エラー",
    $10001: "現在お使いの端末での予約は既に完了しています",
    $10002: "抽選回数が足りません",
    $10003: "このメールアドレスはすでに予約に使用されています",
    $10004: "現在のIPアドレスからの予約は上限に達しました",
    $10005: "招待者が存在しません",
  },
  prize: {
    $1: "専用リンクシェア：",
    $2: "専用リンクをコピーします",
    $3: "コピー",
    $4: "番号",
    $5: "賞品",
    $6: "当選日付",
    $7: "当選番号",
    $8: "*付記：当選日付は日本時間（UTC+9）です。",
    $9: "*付記：ゲームがリリースされた後受け取れます。",
    $10: "最大で5回のガチャ回数を獲得できます",
    $11: "現在の獲得回数：",
    $12: "*公式アカウントをフォローしてガチャを引く回数を獲得する",
  },
  role1: {
    // 腕龙
    name: "ブラキオサウルス",
    level: "橙",
    type: "発展",
    tag: ["草食訓練", "肉食訓練", "雑食訓練"],
    intro:
      "後期ジュラ紀に生息していた竜脚類恐竜の一属。種名は「広い胸郭」を意味する。かつては世界で最も大きな恐竜の一つと考えられていた。また、世界で最も有名な大型草食恐竜でもある。",
  },
  role2: {
    // 牛龙
    name: "カルノタウルス",
    level: "橙",
    type: "戦闘",
    tag: ["全能バフ", "疾行", "速戦即決"],
    intro:
      "学名「カルノタウルス」、白亜紀後期に生息した獣脚類の恐竜の一属である。その属名は「肉食の雄牛」を意味し、動きが最も速い大型獣脚類恐竜の一つである。",
  },
  role3: {
    // 棘龙
    name: "スピノサウルス",
    level: "橙",
    type: "戦闘",
    tag: ["雑食バフ", "疾行", "速戦即決"],
    intro:
      "別名スピノサウルス、スピネサウルスとも呼ばれ、後期白亜紀に生息していた。その属名は「トゲのあるトカゲ」を意味し、現在知られている陸生肉食恐竜の中で最大級のものの一つであり、最も長い肉食恐竜の一つでもある。",
  },
  role4: {
    // 甲龙
    name: "アンキロサウルス",
    level: "橙",
    type: "戦闘",
    tag: ["草食バフ", "疾行", "速戦即決"],
    intro:
      "後期白亜紀に生息していた。属名は「硬直したトカゲ」を意味し、装甲恐竜の一属である。特徴は全身を骨板で覆われた体と棒のような巨大な尾、体重は2トンから4トンである。同じ体長の恐竜に比べて背が低くため、重心を下げて柔らかい腹部を甲羅で守ることができる。",
  },
  role5: {
    // 霸王龙
    name: "ティラノサウルス",
    level: "橙",
    type: "戦闘",
    tag: ["肉食バフ", "疾行", "速戦即決"],
    intro:
      "後期白亜紀に生息していた。属名は「硬直したトカゲ」を意味し、装甲恐竜の一属である。特徴は全身を骨板で覆われた体と棒のような巨大な尾、体重は2トンから4トンである。同じ体長の恐竜に比べて背が低くため、重心を下げて柔らかい腹部を甲羅で守ることができる。",
  },

  // 异兽
  role6: {
    // 野马
    name: "ノウマ",
    level: "橙",
    type: "戦闘",
    tag: ["ダメージ", "沈黙", "ダメージ軽減"],
    intro:
      "ウマ科ウマ属。世界中に広く分布し、地域によって異なる種類が存在する。 頑丈でたくましく、長く均整のとれた四肢を持ち、強い性格が特徴である。 群れでの生活を好み、通常は雄馬と数頭の雌馬とその子馬で構成される。各群れは明確な行動範囲を有し、1日に5～10キロのペースで移動する。",
  },
  role7: {
    // 小熊猫
    name: "レッサーパンダ",
    level: "紫",
    type: "採集",
    tag: ["発展", "果物収集"],
    intro:
      "食肉目レッサーパンダ科に分類される哺乳綱。ミャンマー北部、ネパール、インド北東部、中華人民共和国（四川省西部、雲南省の標高2,200～4,800メートルのヒマラヤ山脈）の大きな木や岩の洞窟や岩の隙間に生息している。朝夕に餌を探しに出てくることが多く、昼間は洞窟内や大きな木の陰で寝ていることが多い。",
  },
  role8: {
    // 懒猴
    name: "スローロリス",
    level: "橙",
    type: "採集",
    tag: ["発展", "ハチミツ収集"],
    intro:
      "霊長目ロリス科に分類されるスローロリス属の構成種の総称。東南アジアとその周辺に分布し、雑食性で、樹液、花蜜、果物、昆虫、小鳥等を食料とする。一般的に常緑樹林に生息しており、密な林冠の連続した熱帯多雨林に好んで生息する。",
  },
  role9: {
    // 西伯利亚虎
    name: "アムールトラ",
    level: "橙",
    type: "戦闘",
    tag: ["ダメージ", "単点突破"],
    intro:
      "ネコ科に属するトラの一亜種。ロシア極東の沿海地方と中華人民共和国の東北部の吉林·黒竜江地域に分布している。「万獣の王」と呼ばれる世界屈指の捕食者。 世界で最も有名な動物のひとつである。",
  },
  role10: {
    // 白孟加拉虎
    name: "ホワイトタイガー",
    level: "橙",
    type: "戦闘",
    tag: ["ダメージ", "制圧", "群体猛撃"],
    intro:
      "ネコ科の動物の一種トラの中で、インドに分布する亜種である。20世紀に絶滅の危機にさらされたが、1970年代からはじまった保護政策により頭数が回復した。インドの国宝である。体色はオレンジや赤褐色で、腹は黒い。頬や耳介の内側は白い体毛で被われている。縞は少なく、肩や胸に縞がない場合もある。",
  },
  role11: {
    // 草原狮
    name: "ライオン",
    level: "橙",
    type: "戦闘",
    tag: ["ダメージ", "群体猛撃"],
    intro:
      "ネコ科ヒョウ属。アフリカ、アジア、インドの草原地帯に広く分布している。現存するネコ科で平均体重は最大である。オスは一段と大きく、力強い体を持ち、メスはやや小柄であり、性別による差異が明瞭。アフリカ大陸のトップ捕食者、「百獣の王」と呼ばれている。",
  },

  ...jaLocale,
};
export default ja;
