<template>
  <div
    class="home"
    :style="{
      'background-image': 'url(' + imgInfo.bg_img + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    }"
  >
    <div class="content">
      <div class="content-area">
        <img class="title" :src="imgInfo.title_img" alt="" />
        <div
          class="people"
          :style="{
            'background-image': 'url(' + imgInfo.reach_bg + ')',
            'background-size': 'cover',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
          }"
        >
          <span v-html="addCommas($t('reward.peopleNum'))"></span>
          <img
            src="@/assets/pc/reward/bg_punct.png"
            alt=""
            @click="showTipsModal"
          />
        </div>
        <div class="area">
          <div
            class="area_item"
            v-for="(item, index) in imgInfo.reward_list"
            :key="index"
            :style="{
              'background-image': 'url(' + item.img + ')',
              'background-size': 'contain',
              'background-repeat': 'no-repeat',
              'background-position': 'center center',
            }"
          >
            <div
              class="reach"
              :class="{ end_reach: index === 4 }"
              v-if="item.reach"
              :style="{
                'background-image': 'url(' + imgInfo.reach_img + ')',
                'background-size': 'contain',
                'background-repeat': 'no-repeat',
                'background-position': 'center center',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";

export default {
  name: "component_name",
  data() {
    return {
      activePeopleNum: 0,
      peopleNumberObj: {
        0: { total: 51055, min: 5, max: 8 },
        1: { total: 109964, min: 7, max: 8 },
        2: { total: 168873, min: 6, max: 9 },
        3: { total: 231709, min: 7, max: 9 },
        4: { total: 294545, min: 6, max: 10 },
        5: { total: 361309, min: 7, max: 10 },
        6: { total: 432000, min: 8, max: 10 },
        7: { total: 514473, min: 9, max: 12 },
        8: { total: 577309, min: 7, max: 9 },
        9: { total: 636218, min: 6, max: 9 },
        10: { total: 691200, min: 6, max: 8 },
        11: { total: 738327, min: 5, max: 7 },
        12: { total: 781527, min: 4, max: 7 },
        13: { total: 820800, min: 4, max: 6 },
        14: { total: 856145, min: 4, max: 5 },
        15: { total: 887564, min: 3, max: 5 },
        16: { total: 915055, min: 3, max: 4 },
        17: { total: 942545, min: 3, max: 4 },
        18: { total: 970036, min: 3, max: 4 },
        19: { total: 989673, min: 2, max: 3 },
        20: { total: 1009309, min: 2, max: 3 },
        21: { total: 1028945, min: 2, max: 3 },
        22: { total: 1044655, min: 1, max: 3 },
        23: { total: 1060364, min: 1, max: 3 },
        24: { total: 1072145, min: 1, max: 2 },
        25: { total: 1083927, min: 1, max: 2 },
        26: { total: 1095709, min: 1, max: 2 },
      },
    };
  },
  watch: {
    activePeopleNum: {
      handler(val) {
        if (val) {
          console.log("当前预约人数=>", val);
        }
      },
    },
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        JA: {
          bg_img: require("../../assets/pc/reward/bg3.png"),
          title_img: require("../../assets/pc/reward/bg_title.png"),
          reach_bg: require("../../assets/pc/reward/bg_bp.png"),
          reward_list: [
            {
              reach: this.activePeopleNum >= 100000,
              img: require("@/assets/pc/reward/bp_gift1.png"),
            },
            {
              reach: this.activePeopleNum >= 200000,
              img: require("@/assets/pc/reward/bp_gift2.png"),
            },
            {
              reach: this.activePeopleNum >= 300000,
              img: require("@/assets/pc/reward/bp_gift3.png"),
            },
            {
              reach: this.activePeopleNum >= 500000,
              img: require("@/assets/pc/reward/bp_gift4.png"),
            },
            {
              reach: this.activePeopleNum >= 1000000,
              img: require("@/assets/pc/reward/bp_gift5.png"),
            },
          ],
          reach_img: require("@/assets/pc/reward/bp_dc.png"),
        },
      };
      return imgObj[this.region];
    },

    homeInfo() {
      return {
        bg: require("@/assets/pc/gift/bg.png"),
        name: "home",
      };
    },
    addCommas() {
      return (text) => {
        let str = text.replaceAll(
          "X",
          `<span style="
            font-size: 1.05rem;
            height: 1.05rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #FFEA8A;
            margin:0rem 0.2rem">
            ${this.activePeopleNum}
            </span>`
        );
        return str;
      };
    },
    giftList() {
      return [
        {
          bg: require("@/assets/pc/gift/award_5.png"),
          introduce: require("@/assets/pc/gift/introduce_5.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_10.png"),
          introduce: require("@/assets/pc/gift/introduce_10.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_20.png"),
          introduce: require("@/assets/pc/gift/introduce_20.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_35.png"),
          introduce: require("@/assets/pc/gift/introduce_35.png"),
        },
        {
          bg: require("@/assets/pc/gift/award_50.png"),
          introduce: require("@/assets/pc/gift/introduce_50.png"),
        },
      ];
    },
  },
  mounted() {
    let today = new Date();
    // 对当前系统时间的偏移量
    let offset = today.getTimezoneOffset() * 60000;
    let utcTime = today.getTime() + offset + 9 * 60 * 60000; // 日区 = utc+9h
    let curTime = new Date(utcTime).getTime();

    // 结束时间的utc时间，转为本地时区时间，在计算对应utc时间戳
    let endLocalTime = convertUTCTimeToLocalTime("2024-05-14T15:00:00.000Z");
    let endUtcTime =
      new Date(endLocalTime).getTime() +
      new Date(endLocalTime).getTimezoneOffset() * 60000 +
      9 * 60 * 60000;
    let endTime = new Date(endUtcTime).getTime();
    let curDay = 27 - Math.ceil((endTime - curTime) / (24 * 60 * 60000));
    let passTime = Math.ceil(
      (new Date(utcTime).getHours() * 60 * 60 * 1000 +
        new Date(utcTime).getMinutes() * 60 * 1000 +
        new Date(utcTime).getSeconds() * 1000 +
        new Date(utcTime).getMilliseconds()) /
        22000
    );
    if (curDay >= 27) {
      this.timer = null;
      this.activePeopleNum = this.peopleNumberObj[26].total;
      return;
    } else if (curDay < 0) {
      this.timer = null;
      this.activePeopleNum = 0;
      return;
    }
    let { max, min } = this.peopleNumberObj[curDay];
    // 刷新，首次进入按照当天已过秒数根据增长曲线计算已有人数
    let hasGetTodayPeople = Math.round(passTime * (max + min));
    this.activePeopleNum =
      curDay == 0
        ? hasGetTodayPeople
        : this.peopleNumberObj[curDay - 1].total + hasGetTodayPeople;

    // 每日最后11s人数自动赋值当天目标最大值
    // 当天的23:59:49
    var todayEndFirstTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86378000
    ).getTime(); //毫秒
    // 第二天的00:00:00
    var todayEndLastTime = new Date(
      new Date(new Date(utcTime).toLocaleDateString()).getTime() + 86400000
    ).getTime(); //毫秒

    if (
      new Date(utcTime).getTime() >= todayEndFirstTime &&
      new Date(utcTime).getTime() <= todayEndLastTime
    ) {
      this.activePeopleNum = this.peopleNumberObj[curDay].total;
    }
  },

  methods: {
    isInRange(value, min, max) {
      return value >= min && value < max;
    },
    showTipsModal() {
      this.$tipModal({
        type: "type2",
        onClose: () => {
          console.log("对话框已关闭");
        },
      });
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .content {
    width: 100%;
    height: calc(100% - 5.56rem);
    margin-top: 5.56rem;
    position: relative;
    &-area {
      width: 57.4%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      left: 22%;
      top: 6.47%;
      .title {
        width: 30rem;
      }
      .people {
        width: 26.13rem;
        height: 3.13rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -1rem;
        span {
          font-size: 1.05rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          height: 1.05rem;
          line-height: 1.05rem;
          letter-spacing: 0.2rem;
        }
        img {
          cursor: pointer;
        }
      }
      .area {
        width: 100%;
        // height: 55%;
        height: 29rem;

        margin-top: 2.5rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;
        &_item {
          width: calc((100% - 1rem) / 3);
          height: 57%;
          // margin-bottom: 0.5%;
          transform: scale(1.1);
          position: relative;
          &:last-child {
            width: calc((100% - 2.25rem) / 3 * 2 + 2.1rem);
            height: 69.5%;
            // margin-top: -1.5%;
            position: absolute;
            bottom: -19.5%;
            right: -1%;
          }
          .reach {
            width: 5.5rem;
            height: 5.5rem;
            position: absolute;
            bottom: 5%;
            right: 6%;
          }
          .end_reach {
            width: 5.5rem;
            height: 5.5rem;
            position: absolute;
            bottom: 8.5%;
            right: 6.5%;
          }
        }
      }
    }
  }
}
@media screen and (min-height: 650px) and (max-height: 750px) {
  .content-area {
    top: 5% !important;
  }
}
</style>
