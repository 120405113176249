<template>
  <div
    class="home bs_100"
    :style="{
      'background-image':
        'url(' + require('@/assets/mobile/home/bg_pic.png') + ')',
    }"
  >
    <div class="content">
      <div class="content_intro">
        <img class="logo" :src="imgInfo.intro_img" alt="" />
        <img
          class="play"
          @click="openVideoModal"
          :src="imgInfo.paly_bg"
          alt=""
        />
      </div>
      <div class="content_download">
        <img
          :src="item.img"
          alt=""
          v-for="(item, img_index) in imgInfo.link_list"
          :key="img_index"
          @click="openLink(item.name)"
        />
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="video" v-if="showVideoModal">
        <div class="video-center">
          <img
            class="close_img"
            @click="closeVideoModal"
            src="@/assets/mobile/popup/popup_closebutton.png"
            alt=""
          />
          <video
            ref="video"
            controls
            preload="auto"
            autoplay="”autoplay”"
            @loadedmetadata="loadedmetadata"
            @progress="progress"
          >
            <source src="@/assets/video/video_p_ja.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MobileRegister from "@/components/MobileRegister";
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";
import mVideo from "@/assets/video/video_m_ja.mp4";

export default {
  name: "component_name",
  components: {
    MobileRegister,
  },
  data() {
    return {
      activePeopleNum: 0,
      showDesModal: false,
      showVideoModal: false,
      countLanguageVideo: mVideo,
      // https://cdn9.platform-test-cdn.allstarunion.com/2024/03/01/280ebc6eb4bf44878b88ce8eafaee654.mp4
    };
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region"]),
    imgInfo() {
      const imgObj = {
        JA: {
          intro_img: require("@/assets/mobile/home/title.png"),
          head_img: require("@/assets/pc/home/home_icon.png"),
          link_list: [
            {
              name: "ios",
              img: require("@/assets/mobile/home/download_appstore.png"),
            },
            {
              name: "google",
              img: require("@/assets/mobile/home/download_googleplay.png"),
            },
          ],
          paly_bg: require("@/assets/mobile/home/play_pre.png"),
        },
      };
      return imgObj[this.region];
    },
  },
  mounted() {},

  methods: {
    ...mapMutations(["openNewMsg"]),

    loadedmetadata() {},
    openVideoModal() {
      this.showVideoModal = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.$refs.video.load();
          this.$refs.video.play();
        }, 1000);
      });
    },
    progress(v) {},
    closeVideoModal() {
      this.$refs.video.pause();
      this.showVideoModal = false;
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Click Ios store",
        });
        window.open("https://apps.apple.com/app/id6475638006", "_blank");
      } else {
        trackClick({
          behavior_tag: "Click Google store",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          "_blank"
        );
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes scale_move {
  0% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.4);
  }
}
.home {
  width: 100%;
  height: 115rem;
  position: relative;
  z-index: 999;
  .content {
    width: 44.5rem;
    height: 100px;
    position: absolute;
    left: 50%;
    top: 62.45%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &_intro {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .logo {
        width: 41.28rem;
        transform: scale(1.22);
      }
      .play {
        width: 3.5rem;
        height: 3.5rem;
        margin-bottom: 4rem;
        animation: scale_move 1.5s linear infinite;
      }
    }
    &_download {
      width: 100%;
      height: 6.35rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0rem;
      img {
        width: calc((100% - 2.5rem) / 2);
        height: 100%;
      }
    }
  }
  .video {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 100001;
    display: flex;
    justify-content: center;
    align-items: center;

    &-center {
      width: 90%;
      height: 65vh;
      position: relative;
      video {
        width: 100%;
        height: 100%;
        object-fit: fill;
        border-radius: 0.8rem;
      }

      .close_img {
        position: absolute;
        width: 3rem;
        height: 3rem;
        top: -4.5rem;
        right: -2rem;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
      }
    }
  }
}
</style>
