var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"character",style:({
    'background-image': 'url(' + _vm.imgInfo.info_list[_vm.cur_act_index].bg + ')',
    'background-size': '100%',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
  })},[_c('div',{staticClass:"character_top"},[_c('img',{staticClass:"l_arrow",style:({ top: _vm.cur_act_type_index ? '-0.5rem' : '0.3rem' }),attrs:{"src":require("@/assets/mobile/character/title2_button1.png"),"alt":""},on:{"click":_vm.handelChangeType}}),_c('div',{staticClass:"c_img bs_contain",style:({
        'background-image':
          'url(' + _vm.imgInfo.switch_type_imgs[_vm.cur_act_type_index] + ')',
      })}),_c('img',{staticClass:"r_arrow",style:({ bottom: _vm.cur_act_type_index ? '0.6rem' : '0.5rem' }),attrs:{"src":require("@/assets/mobile/character/title_button2.png"),"alt":""},on:{"click":_vm.handelChangeType}})]),_c('div',{staticClass:"character_bottom"},[_c('div',{staticClass:"arrow bs_contain",style:({
        'background-image':
          'url(' + require('@/assets/mobile/character/button.png') + ')',
      }),on:{"click":function($event){return _vm.handelViewCharacter('prev')}}}),_c('div',{staticClass:"head-area"},[_c('div',{staticClass:"head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[0].head_def + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('prev')}}}),_c('div',{staticClass:"head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[1].head_hover + ')',
        })}),_c('div',{staticClass:"head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[2].head_def + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('next')}}})]),_c('div',{staticClass:"arrow bs_contain",style:({
        'background-image':
          'url(' + require('@/assets/mobile/character/button2.png') + ')',
      }),on:{"click":function($event){return _vm.handelViewCharacter('next')}}})]),_c('div',{staticClass:"character_center"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title_text"},[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].name)+" ")]),_c('img',{attrs:{"src":_vm.cur_act_index < 6
            ? require('@/assets/mobile/character/icon.png')
            : require('@/assets/mobile/character/icon2.png'),"alt":""}})]),_c('span',{staticClass:"skill"},[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].tag)+" ")]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"type_left"},[_c('img',{attrs:{"src":require("@/assets/mobile/character/bp_title.png"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].level)+" ")])]),_c('div',{staticClass:"type_right"},[_c('img',{attrs:{"src":require("@/assets/mobile/character/bp_title2.png"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].type)+" ")])])]),_c('div',{staticClass:"intro"},[_c('span',[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].intro)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }