import axios from "axios";
import store from "../store/index";
import { signer } from "./crypto";
import { convertFormdata } from "../tools/formdata";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 60000, // request timeout
});
// request interceptor
service.interceptors.request.use(
  (config) => {
    const { data, isFile, type = "base-api" } = config;
    // console.log('config=>', config);

    config.baseURL =
      type === "open-gateway"
        ? process.env.VUE_APP_OPEN_API
        : process.env.VUE_APP_BASE_API;

    if (data) {
      let reqData = {};
      if (typeof data === "string") {
        reqData = JSON.parse(data);
      } else {
        reqData = data;
      }
      let cloneData = JSON.parse(JSON.stringify(reqData));
      if (cloneData.file) {
        delete cloneData.file;
      }
      const { ts, seed, sign } = signer(cloneData);
      data.ts = ts;
      data.seed = seed;
      data.signature = sign;
    }
    if (isFile) {
      config.headers["Content-Type"] = "multipart/form-data";
      // 需要知道是否是重连的数据，已经被转为formdata
      if (!(config.data instanceof FormData)) {
        config.data = convertFormdata(config.data);
      }
      config.retry = 999; //重试次数
      config.retryDelay = 60 * 1000; // 请求间隙
    }
    return config;
  },
  (error) => {
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const { data: res } = response;
    if (res.code !== 20000) {
      if (res.code === 50038) {
        var config = response.config;
        // 如果配置不存在或未设置重试选项，则拒绝
        if (!config || !config.retry) return { ...res };
        // 设置变量以跟踪重试次数
        config.__retryCount = config.__retryCount || 0;
        // 判断是否超过总重试次数
        if (config.__retryCount >= config.retry) {
          // 返回错误并退出自动重试
          return { ...res };
        }

        // 增加重试次数
        config.__retryCount += 1;

        //打印当前重试次数
        console.log(config.url + " 自动重试第" + config.__retryCount + "次");

        // 创建新的Promise
        var backoff = new Promise(function (resolve) {
          setTimeout(function () {
            resolve();
          }, config.retryDelay || 1);
        });

        // 返回重试请求
        return backoff.then(function () {
          return service(config);
          // return service({ ...config, ...{ data: config.data } });
        });
      } else {
        console.log(`err${res.code}=>`, res.message);
        return { ...res };
      }
    } else {
      return { ...res };
    }
  },
  (err) => {
    var config = err.config;
    // 如果配置不存在或未设置重试选项，则拒绝
    if (!config || !config.retry) return Promise.reject(err);

    // 设置变量以跟踪重试次数
    config.__retryCount = config.__retryCount || 0;

    // 判断是否超过总重试次数
    if (config.__retryCount >= config.retry) {
      // 返回错误并退出自动重试
      return Promise.reject(err);
    }

    // 增加重试次数
    config.__retryCount += 1;

    //打印当前重试次数
    console.log(config.url + " 自动重试第" + config.__retryCount + "次");

    // 创建新的Promise
    var backoff = new Promise(function (resolve) {
      setTimeout(function () {
        resolve();
      }, config.retryDelay || 1);
    });

    // 返回重试请求
    return backoff.then(function () {
      return service(config);
    });
  }
);

export default service;
