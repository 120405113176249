function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

const crypto = require("crypto");

function Str2Bytes(str) {
  let pos = 0;

  let len = str.length;

  if (len % 2 != 0) {
    return null;
  }

  len /= 2;

  const hexA = new Array();

  for (let i = 0; i < len; i++) {
    const s = str.substr(pos, 2);

    const v = parseInt(s, 16);

    hexA.push(v);

    pos += 2;
  }

  return hexA;
}

const priK = `-----BEGIN RSA PRIVATE KEY-----
MIIBOQIBAAJBANnNLY2jLg3nCjznsk88p93eoV5bKznEy2Yhx+Z0ymSMtEH5ywhb
eDrUdnatzbr9PlKLN7D9f7bFIO+e5aZeqzkCAwEAAQJAYJUx7Cs+kv+vdI6ybZzS
O/srx/HZ20Y/hEzanoGP9sH0uGr4nsmn9e5bfjm0yu/soknXHXGYFrLdusteXjWS
uQIhAN+HxHxVmQCMAQ40OgttKzomTOEpU6e2e6Ye5tbr7ujnAiEA+XBgP+/azu2u
qYb9yH/rEnnzPWVkIqlalSOL/GRkpt8CIA7+YGOmqjirK3b0ceBKVlf0Mbv4ta/O
QcUG1Z0c/k2JAiAqTffVAC4BCGimEeH63k8VDB/H2ulXw5c8UhIM1U4IywIgVTnN
LmtehykxYmoTCxgzLgsHwWurROX4mN5RK2RLtjk=
-----END RSA PRIVATE KEY-----`;

// eslint-disable-next-line import/prefer-default-export
export function signer(param) {
  const paramList = Object.keys(param);
  paramList.push("ts", "seed");
  paramList.sort();
  const changeParamList = deepClone(paramList);
  const { length } = paramList;
  const seed = Math.floor(Math.random() * length + 1);
  const ts = Date.parse(new Date()) / 1000;
  const tsIdx = paramList.findIndex((i) => i === "ts");
  changeParamList.splice(tsIdx, 1);
  changeParamList.unshift("ts");
  if (paramList[seed]) {
    if (paramList[seed] === "ts") {
      changeParamList.push("ts");
    } else {
      const lastIdx = paramList.findIndex((i) => i === paramList[seed]);
      const lastKey = paramList[lastIdx];
      const delIdx = changeParamList.findIndex((i) => i === paramList[seed]);
      changeParamList.splice(delIdx, 1);
      changeParamList.push(lastKey);
    }
  }
  let signStr = "";
  // eslint-disable-next-line array-callback-return
  changeParamList.map((i, idx) => {
    let data =
      Array.isArray(param[i]) ||
      Object.prototype.toString.call(param[i]) === "[object Object]"
        ? JSON.stringify(param[i])
        : param[i];
    if (i === "ts") {
      data = ts;
    } else if (i === "seed") {
      data = seed;
    }
    signStr += `${i}$${data}`;
    if (idx !== changeParamList.length - 1) {
      signStr += "&";
    }
  });

  if (seed === changeParamList.length) {
    signStr += "&";
  }
  const algorithm = "RSA-SHA256";
  // console.log('待签明文', signStr);

  const signature = crypto.createSign(algorithm);
  signature.update(signStr);
  // 签名
  const sign = signature.sign(priK, "hex");
  // b64
  const s = window.btoa(String.fromCharCode.apply(null, Str2Bytes(sign)));

  return {
    ts,
    seed,
    sign: s,
  };
}
