<template>
  <div class="lottery">
    <!-- 显示当前帧的图片 -->
    <img v-if="currentFrame" :src="currentFrame" alt="Animation Frame" />
  </div>
</template>
  
<script>
const imageCache = {};
export default {
  data() {
    return {
      frames: [], // 存储序列帧的数组
      currentIndex: 0, // 当前显示的帧索引
      frameRate: 15, // 每秒帧数
    };
  },
  computed: {
    currentFrame() {
      // 得到当前索引对应的帧图片路径
      return imageCache[this.currentIndex]?.src
        ? imageCache[this.currentIndex]?.src
        : "";
    },
  },
  methods: {
    startAnimation() {
      const frameCount = this.frames.length;
      const delay = 1000 / this.frameRate;

      // 定义一个循环播放序列帧的函数
      const loop = () => {
        this.currentIndex = (this.currentIndex + 1) % frameCount; // 更新索引
        this.animationFrameId = setTimeout(loop, delay); // 设置下一帧的超时
      };

      this.animationFrameId = setTimeout(loop, delay);
    },
    stopAnimation() {
      clearTimeout(this.animationFrameId); // 停止动画
    },
    preloadImages(urls) {
      return new Promise((resolve, reject) => {
        urls.forEach((url, index) => {
          if (!imageCache[url]) {
            const img = new Image();
            img.onload = () => {
              imageCache[index] = img;

              // 检查是否所有图片都被加载

              if (Object.keys(imageCache).length === urls.length) {
                // 执行全部加载完成后的操作
                resolve();
              }
            };
            img.src = url;
          }
        });
      });
    },
  },
  async mounted() {
    // 初始化序列帧数组
    this.frames = [
      require("@/assets/lottery_effect/bp_giftbg_0000.png"),
      require("@/assets/lottery_effect/bp_giftbg_0001.png"),
      require("@/assets/lottery_effect/bp_giftbg_0002.png"),
      require("@/assets/lottery_effect/bp_giftbg_0003.png"),
      require("@/assets/lottery_effect/bp_giftbg_0004.png"),
      require("@/assets/lottery_effect/bp_giftbg_0005.png"),
      require("@/assets/lottery_effect/bp_giftbg_0006.png"),
      require("@/assets/lottery_effect/bp_giftbg_0007.png"),
      require("@/assets/lottery_effect/bp_giftbg_0008.png"),
      require("@/assets/lottery_effect/bp_giftbg_0009.png"),
      require("@/assets/lottery_effect/bp_giftbg_0010.png"),
      require("@/assets/lottery_effect/bp_giftbg_0011.png"),
      require("@/assets/lottery_effect/bp_giftbg_0012.png"),
      require("@/assets/lottery_effect/bp_giftbg_0013.png"),
      require("@/assets/lottery_effect/bp_giftbg_0014.png"),
    ];
    await this.preloadImages(this.frames);
    this.startAnimation();

    // 开始动画
  },
  beforeDestroy() {
    // 组件销毁前停止动画

    this.stopAnimation();
  },
};
</script>
<style lang="scss" scoped>
.lottery {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>