<template>
  <div class="prize">
    <div
      class="prize_title bs_contain"
      :style="{
        'background-image': 'url(' + imgInfo.title_img + ')',
      }"
    >
      <img :src="imgInfo.ques_img" alt="" @click="showTipsModal" />
    </div>
    <div
      class="prize-area bs_100"
      :style="{
        'background-image': 'url(' + imgInfo.area_bg + ')',
      }"
    >
      <div class="prize-area_left">
        <div class="lottery-container">
          <div
            v-for="(item, index) in imgInfo.prize_list"
            :key="index"
            :class="[
              'lottery-grid',
              'bs_100',
              // { highlight: currentIndex === index },
              { highlight: true },
              { start: index === 4 },
            ]"
            v-loading="index === 4 && lotteryLoading"
            :style="{
              'background-image':
                index !== 4
                  ? 'url(' + item.prize_img + ')'
                  : 'url(' + item.start_img + ')',
            }"
            @click="startLottery(index)"
          >
            <!-- <div
              class="price_img bs_contain"
              :style="{
                'background-image':
                  index !== 4 ? 'url(' + item.prize_img + ')' : '',
                width: item.width,
              }"
            ></div> -->
            <div v-if="index == 4" class="lottery-button">
              {{ item.name }}
            </div>
            <lotteryEffect
              v-if="currentIndex === index"
              class="lottery-effect"
            />
          </div>
        </div>
      </div>
      <div class="prize-area_right">
        <div class="item">
          <div
            class="item_title1 bs_cover"
            :style="{
              'background-image': 'url(' + imgInfo.link_title_bg + ')',
            }"
          ></div>
          <div class="item_link">
            <img
              :src="item.img"
              alt=""
              v-for="(item, index) in imgInfo.link_list"
              :key="index"
              @click="jumpLink(item)"
            />
          </div>
        </div>
        <div class="item">
          <div
            class="item_title2 bs_cover"
            :style="{
              'background-image': 'url(' + imgInfo.share_title_bg + ')',
            }"
          ></div>
          <div class="item_share">
            <span class="tip">{{ $t("prize.$1") }}</span>
            <div class="share">
              <div
                class="share_link bs_100"
                :style="{
                  'background-image': 'url(' + imgInfo.share_link_bg + ')',
                }"
              >
                <span>{{
                  current_share_link ? current_share_link : $t("prize.$2")
                }}</span>
              </div>
              <div
                class="share_btn bs_contain"
                ref="copyButton"
                @click="copyToClipboard"
                :style="{
                  'background-image': 'url(' + imgInfo.share_btn_bg + ')',
                }"
              >
                <span>{{ $t("prize.$3") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="item">
          <div
            class="item_title3 bs_cover"
            :style="{
              'background-image': 'url(' + imgInfo.reward_title_bg + ')',
            }"
          ></div>
          <div class="item_reward">
            <img src="@/assets/mobile/prize/bp_line.png" alt="" />
            <div class="reward_list">
              <div
                class="card"
                v-for="(item, index) in imgInfo.reward_list"
                :key="index"
                @click="handelDrawInvitePrize(item.id)"
              >
                <div
                  class="card_img bs_cover"
                  :style="{
                    'background-image':
                      'url(' +
                      (currentPrizeCanDraw(item.id) ? item.bg : item.bg_done) +
                      ')',
                  }"
                >
                  <InvitePrizeEffect
                    v-if="
                      currentPrizeCanDraw(item.id) &&
                      lottery_source_share_length >= item.id
                    "
                    class="invite-effect"
                  />
                </div>
                <span class="card_title">{{ item.title2 }}</span>
                <span class="card_text">{{ item.text1 }}</span>
                <span class="card_text">{{ item.text2 }}</span>
                <span v-if="item.text3" class="card_text">{{
                  item.text3
                }}</span>
                <img
                  class="card_achieve"
                  :src="
                    currentPrizeCanDraw(item.id)
                      ? imgInfo.share_achieve
                      : imgInfo.share_not_achieve
                  "
                  alt=""
                  v-if="lottery_source_share_length >= item.id"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="btn">
          <img
            v-for="(item, index) in imgInfo.btn_list"
            :key="index"
            :src="item"
            alt=""
            @click="showModal(index)"
          />
        </div>
      </div>
    </div>
    <prizeRule
      :visible="prizeRuleVisible"
      @closeModal="closeModal('prizeRuleVisible')"
      @handelGetLottery="handelGetLottery"
      @handelTwitterGetLottery="handelTwitterGetLottery"
    />
    <prizeRecordModal
      :visible="prizeRecordVisible"
      @closeModal="closeModal('prizeRecordVisible')"
    />
    <drawInvitePrizeModal
      :visible="drawInvitePrizeInfo.visible"
      :cdk_code="drawInvitePrizeInfo.cdk_code"
      :id="drawInvitePrizeInfo.id"
      @closeModal="closeDrawInvitePrizeModal"
    />
    <registerSuccessModal
      :visible="registerSuccessInfo.visible"
      :type="registerSuccessInfo.type"
      :cdk_code="registerSuccessInfo.cdk_code"
      @closeModal="closeRegisTipsModal"
      @openLink="openLink"
    />
  </div>
</template>
  
  <script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ClipboardJS from "clipboard";
import prizeRecordModal from "./components/prizeRecordModal.vue";
import prizeRule from "./components/prizeRule.vue";
import lotteryEffect from "@/components/lotteryEffect";
import {
  reqHandelLottery,
  reqReceivePrize,
  reqStoreReservation,
} from "@/settings/api.js";
import drawInvitePrizeModal from "@/views/Mobile/components/drawInvitePrizeModal";
import InvitePrizeEffect from "@/components/InvitePrizeEffect";
import registerSuccessModal from "./components/registerSuccessModal.vue";

export default {
  name: "component_name",
  components: {
    prizeRecordModal,
    prizeRule,
    lotteryEffect,
    InvitePrizeEffect,
    drawInvitePrizeModal,
    registerSuccessModal,
  },
  data() {
    return {
      activePeopleNum: 0,
      highlightSequence: [0, 1, 2, 5, 8, 7, 6, 3], // 顺时针高亮顺序
      currentIndex: -1,
      isRunning: false,
      timer: null,
      current_share_link: "",
      prizeRecordVisible: false,
      prizeRuleVisible: false,
      lotteryLoading: false,
      drawInviteLoading: false,
      drawInvitePrizeInfo: {
        visible: false,
        cdk_code: "",
        id: null,
      },
      registerSuccessInfo: {
        visible: false,
        cdk_code: "",
        type: 2, // 关注公众号
      },
    };
  },
  watch: {
    copyUserInfo: {
      handler(val, old_val) {
        if (val.identity_id) {
          this.current_share_link = window.location.search
            ? `${window.location.href}&invite_id=${val.identity_id}`
            : `${window.location.href}?invite_id=${val.identity_id}`;
        }
        if (val?.identity_id && val?.identity_id !== old_val?.identity_id) {
          this.currentIndex = -1;
          this.isRunning = false;
          this.timer = null;
        }
      },
      immediate: true,
      deep: true,
    },
    currentJumpLink: {
      handler(val) {
        if (val) {
          this.handelGetLottery(1);
        }
      },
      immediate: true,
    },
  },
  created() {},
  computed: {
    ...mapState([
      "msg",
      "title",
      "region",
      "swiperEnable",
      "region_type",
      "user_info",
      "lottery_source",
      "drawReceiveList",
      "currentJumpLink",
    ]),
    imgInfo() {
      const imgObj = {
        JA: {
          title_img: require("@/assets/mobile/prize/title.png"),
          ques_img: require("@/assets/mobile/prize/punct.png"),
          area_bg: require("@/assets/mobile/prize/bp.png"),
          prize_bg: require("@/assets/mobile/prize/bp_kuang.png"),
          prize_list: [
            {
              name: "Iphone 15",
              width: "6.5rem",
              probability: 0.0001,
              prize_img: require("@/assets/mobile/prize/bp_gift1.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift1.png"),
            },
            {
              name: "AirPods",

              width: "7.13rem",
              probability: 0.005,
              prize_img: require("@/assets/mobile/prize/bp_gift2.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift2.png"),
            },
            {
              name: "Amazonギフト券1000円分",

              width: "9rem",
              probability: 0.01,
              prize_img: require("@/assets/mobile/prize/bp_gift3.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift3.png"),
            },
            {
              name: "獣王コイン",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/mobile/prize/bp_gift6.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift6.png"),
            },
            {
              name: `(ガチャを引ける回数：${
                this.user_info.can_lottery_num || 0
              })`,
              start_img: this.user_info.can_lottery_num
                ? require("@/assets/mobile/prize/bp_button_normal_kscj.png")
                : require("@/assets/mobile/prize/bp_button_normal_hqcs.png"),
            },
            {
              name: "エサ（特級）",
              width: "10rem",
              probability: 0.1,
              prize_img: require("@/assets/mobile/prize/bp_gift8.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift8.png"),
            },
            {
              name: "奇妙水晶*100",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/mobile/prize/bp_gift5.png"),

              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift5.png"),
            },
            {
              name: "1時間通用加速*2",
              width: "9rem",
              probability: 0.05,
              prize_img: require("@/assets/mobile/prize/bp_gift4.png"),

              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift4.png"),
            },

            {
              name: "ダイヤモンド",
              width: "9rem",
              probability: 0.1,
              prize_img: require("@/assets/mobile/prize/bp_gift7.png"),
              prize_show_img: require("@/assets/pc/prize/gift_img/bp_gift7.png"),
            },
          ],
          link_list: [
            {
              name: "twitter",
              link: "https://twitter.com/BeastLordJP",
              img: require("@/assets/mobile/prize/bp_xlogo.png"),
            },
            {
              name: "facebook",
              link: "https://www.facebook.com/beastlordofficial",
              img: require("@/assets/mobile/prize/bp_fblogo.png"),
            },
            {
              name: "line",
              link: "https://lin.ee/T0OLRoW",
              img: require("@/assets/mobile/prize/bp_linelogo.png"),
            },
          ],
          link_title_bg: require("@/assets/mobile/prize/bp_title.png"),
          share_title_bg: require("@/assets/mobile/prize/bp_title2.png"),
          reward_title_bg: require("@/assets/mobile/prize/bp_title3.png"),
          share_link_bg: require("@/assets/mobile/prize/bp_edit.png"),
          share_btn_bg: require("@/assets/mobile/prize/bp_edit2.png"),
          reward_list: [
            {
              bg: require("@/assets/mobile/prize/bp2_gift1.png"),
              bg_done: require("@/assets/mobile/prize/bp2_gift1_dis.png"),
              title2: "エサ（高級）*3",
              text1: "ダイヤモンド*200",
              text2: "5分間通用加速*8",
              id: 1,
            },
            {
              bg: require("@/assets/mobile/prize/bp2_gift2.png"),
              bg_done: require("@/assets/mobile/prize/bp2_gift2_dis.png"),
              title2: "エサ（高級）*1",
              text1: "奇妙水晶*200",
              text2: "ダイヤモンド*300",
              text3: "1時間通用加速*1",
              id: 2,
            },
            {
              bg: require("@/assets/mobile/prize/bp2_gift3.png"),
              bg_done: require("@/assets/mobile/prize/bp2_gift3_dis.png"),
              title2: "エサ（高級）*2",
              text1: "知恵の実V*5",
              text2: "ダイヤモンド*500",
              text3: "1時間通用加速*1",
              id: 3,
            },
          ],
          share_achieve: require("@/assets/mobile/prize/kelingqu.png"),
          share_not_achieve: require("@/assets/mobile/prize/yilingqu.png"),
          btn_list: [
            require("@/assets/mobile/prize/bp_button_pre_hdgz.png"),
            require("@/assets/mobile/prize/bp_button_pre_zjcx.png"),
          ],
        },
      };
      return imgObj[this.region];
    },
    lottery_num() {
      return this.user_info.can_lottery_num || 0;
    },
    lottery_source_share_length() {
      return (
        this.lottery_source.filter((item) => {
          return item === 3;
        })?.length || 0
      );
    },
    copyUserInfo() {
      return cloneDeep(this.user_info);
    },
    // 当前邀请奖励是否可被领取？
    currentPrizeCanDraw() {
      return (id) => {
        const hasDrawArr = this.drawReceiveList.map((item) => {
          return item?.invited_level;
        });
        return hasDrawArr.includes(id) ? false : true;
      };
    },
  },
  mounted() {},

  methods: {
    ...mapMutations([
      "openNewMsg",
      "setSwiperStatus",
      "setForbidMobileScrollStatus",
      "refreshCanLotteryNum",
      "setUserInfo",
      "setCurrentJumpLink",
    ]),

    isInRange(value, min, max) {
      return value >= min && value < max;
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Click Ios store",
        });
        window.open("https://apps.apple.com/app/id6475638006", "_blank");
      } else {
        trackClick({
          behavior_tag: "Click Google store",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          "_blank"
        );
      }
    },
    closeRegisTipsModal() {
      this.registerSuccessInfo.visible = false;
      this.registerSuccessInfo.type = 2;
    },
    // 直接跳转推特增加次数
    handelTwitterGetLottery(id) {
      if (!this.user_info.identity_id) {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t(`msg.login_tips`),
          autoClose: true,
          time: 2000,
        });
        this.$emit("handleScrollToRegister");

        return;
      } else {
        window.open("https://twitter.com/BeastLordJP", "_blank");
        this.handelGetLottery(1);
      }
    },
    handelGetLottery(id) {
      this.prizeRuleVisible = false;
      if (id === 0) {
        this.$emit("handleScrollToRegister");
      } else if (id === 1) {
        // 有链接需要跳转，同时排除 ’media_source‘  的跳转，只做次数增加
        if (this.currentJumpLink && this.currentJumpLink !== "media_source") {
          window.open(this.currentJumpLink, "_blank");
          this.setCurrentJumpLink("");
        }

        if (!this.user_info.identity_id) {
          // this.openNewMsg({
          //   title: this.$t("msg.title"),
          //   body: this.$t(`msg.login_tips`),
          //   autoClose: true,
          //   time: 2000,
          // });
          // this.$emit("handleScrollToRegister");
          // this.setCurrentJumpLink("");
          return;
        } else {
          // 判断是否已经通过跳转获得过次数

          if (this.lottery_source.includes(2)) {
            return;
          }
        }
        reqStoreReservation({
          app_id: +process.env.VUE_APP_PROJECT_ID,
          identity_id: this.user_info.identity_id,
          region_type: this.region_type,
        })
          .then((res) => {
            if (res.code === 20000) {
              let { can_lottery_num, cdk_code, obtain_lottery_num } = res.data;
              this.setUserInfo({ can_lottery_num });
              this.refreshCanLotteryNum();
              if (obtain_lottery_num) {
                this.$emit("handleScrollToRegister");
                this.registerSuccessInfo = {
                  visible: true,
                  cdk_code,
                  type: 2,
                };
              }
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          })
          .finally(() => {
            this.setCurrentJumpLink("");
          });
      }
    },
    startLottery(ind) {
      return;
      if (!this.user_info.can_lottery_num && ind === 4) {
        this.$tipMobileModal({
          type: "type1",
          onClose: () => {},
        });
        return;
      }
      if (this.isRunning || ind !== 4 || this.lotteryLoading) return;
      trackClick({
        behavior_tag: "Lucky Draw",
      });
      this.lotteryLoading = true;
      reqHandelLottery({
        app_id: +process.env.VUE_APP_PROJECT_ID,
        identity_id: this.user_info.identity_id,
        region_type: this.region_type,
      })
        .then((res) => {
          if (res.code === 20000) {
            let { lottery_level } = res.data;
            this.isRunning = true;

            // 确定奖品索引
            this.prizeIndex = this.selectPrize(lottery_level);

            let speed = 50; // 初始速度
            const minSpeed = 800; // 最小速度
            const speedIncrement = 10; // 每一次变慢的增量
            const duration = 5000; // 总时长

            let currentStepIndex = 0;
            const startTime = Date.now();

            const runLottery = () => {
              this.currentIndex = this.highlightSequence[currentStepIndex];
              currentStepIndex =
                (currentStepIndex + 1) % this.highlightSequence.length;

              // 计算已经过去的时间
              let elapsedTime = Date.now() - startTime;

              // 根据时间计算速度
              if (elapsedTime < duration) {
                // 随着时间推移，减慢速度
                speed += speedIncrement;
                if (speed > minSpeed) speed = minSpeed;
              } else {
                // 时间到了，检查当前是否为选中的奖品
                if (this.currentIndex === this.prizeIndex) {
                  clearTimeout(this.timer);
                  this.isRunning = false;
                  trackClick({
                    behavior_tag: "Lucky Draw",
                    prize: this.imgInfo.prize_list[this.prizeIndex].name || "",
                  });
                  this.refreshCanLotteryNum();
                  this.$tipMobileModal({
                    type: "type5",
                    reward: this.imgInfo.prize_list[this.prizeIndex],
                    onClose: () => {},
                  });
                  return;
                }
              }

              this.timer = setTimeout(runLottery, speed);
            };

            runLottery();
          } else {
            this.refreshCanLotteryNum();
            this.openNewMsg({
              title: this.$t("msg.title"),
              body: this.$t(`errMsg.$${res.code}`),
              autoClose: true,
              time: 1000,
            });
          }
        })
        .finally(() => {
          this.lotteryLoading = false;
        });
    },
    selectPrize(index) {
      // let prizes = cloneDeep(this.imgInfo.prize_list);
      // const totalProbability = prizes.reduce(
      //   (acc, prize) => acc + (prize.probability || 0),
      //   0
      // );
      // let random = Math.random() * totalProbability;
      // for (let i = 0; i < this.highlightSequence.length; i++) {
      //   let prizeIndex = this.highlightSequence[i];
      //   let prize = prizes[prizeIndex];
      //   if (random < prize.probability) {
      //     return prizeIndex;
      //   }
      //   random -= prize.probability;
      // }

      // 如果因为概率计算问题没有选中任何奖品，则默认返回最后一个
      return index < 5 ? index - 1 : index;
    },
    copyToClipboard() {
      const _this = this;
      // 使用一个新的 Clipboard 实例来复制文本
      const clipboard = new ClipboardJS(this.$refs.copyButton, {
        text: () => this.current_share_link,
      });

      clipboard.on("success", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_success"),
          autoClose: true,
          time: 1000,
        });
        trackClick({
          behavior_tag: "Copy Link",
        });
        e.clearSelection(); // 清除高亮选择
        clipboard.destroy(); // 复制成功后释放资源
      });

      clipboard.on("error", (e) => {
        this.openNewMsg({
          title: this.$t("msg.title"),
          body: this.$t("msg.copy_fail"),
          autoClose: true,
          time: 1000,
        });
        clipboard.destroy(); // 遇到错误后释放资源
      });

      // 触发按钮的点击事件以启动复制操作
      this.$refs.copyButton.click();
    },
    showTipsModal() {
      this.$tipMobileModal({
        type: "type3",
        onClose: () => {},
      });
    },
    showModal(ind) {
      this.setForbidMobileScrollStatus(true);

      if (ind) {
        this.prizeRecordVisible = true;
      } else {
        this.prizeRuleVisible = true;
      }
    },
    closeModal(name) {
      this.setForbidMobileScrollStatus(false);

      this[name] = false;
    },
    handelDrawInvitePrize(id) {
      if (
        this.currentPrizeCanDraw(id) &&
        this.lottery_source_share_length >= id
      ) {
        if (this.drawInviteLoading) {
          return;
        }
        this.drawInviteLoading = true;
        reqReceivePrize({
          app_id: +process.env.VUE_APP_PROJECT_ID,
          identity_id: this.user_info.identity_id,
          invited_level: id,
          region_type: this.region_type,
        })
          .then((res) => {
            if (res.code === 20000) {
              let { cdk_code } = res.data;
              this.$parent.$parent.getInviteSource();
              this.setForbidMobileScrollStatus(true);

              this.drawInvitePrizeInfo = {
                visible: true,
                cdk_code,
                id,
              };
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          })
          .finally(() => {
            this.drawInviteLoading = false;
          });
      }
    },
    closeDrawInvitePrizeModal() {
      this.setForbidMobileScrollStatus(false);

      this.drawInvitePrizeInfo.visible = false;
      this.drawInvitePrizeInfo.cdk_code = "";
      this.drawInvitePrizeInfo.id = null;
    },
    jumpLink(item) {
      let { name, link } = item;
      trackClick({
        behavior_tag: name,
      });
      this.setCurrentJumpLink(link);
    },
  },
  beforeDestroy() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },

  destroyed() {},
};
</script>
  
  <style lang="scss" scoped>
.prize {
  width: 100%;
  height: 137rem;
  margin-top: 14rem;
  position: relative;
  z-index: 100;

  &_title {
    width: 55.2rem;
    height: 8rem;
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0);
    img {
      width: 2.5rem;
      height: 2.5rem;
      position: absolute;
      right: -1rem;
      top: 1.5rem;
      cursor: pointer;
    }
  }
  &-area {
    width: calc(100% - 5rem);
    height: 128rem;
    display: flex;

    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 9rem;
    box-sizing: border-box;
    padding: 4rem 4.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    &_left {
      width: 100%;
      height: 51rem;
      margin-top: 1rem;
      .lottery-container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(3, calc((100% - 2.5rem) / 3));
        grid-template-rows: repeat(3, calc((100% - 4rem) / 3));
        grid-row-gap: 2rem;
        grid-column-gap: 1.5rem;
        justify-content: center;
      }
      .lottery-grid {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;

        position: relative;
        transform: scale(1.05);

        .price_img {
          width: 8rem;
          height: 8rem;
          margin-bottom: 1.5rem;
        }
        .prize_name {
          font-size: 0.9rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff;
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: 10%;
          white-space: nowrap;
        }
      }
      .highlight {
        position: relative;
        .lottery-effect {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%) scale(1.2, 1.3);
        }
      }
      .start {
        cursor: pointer;
        transform: translate(0, 2.5%) scale(1.2);
        &:hover {
          transform: translate(0, 2.5%) scale(1.21);
        }
      }
      .lottery-button {
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #edcd5e;
        line-height: 1rem;

        background: linear-gradient(0deg, #723723 0%, #502919 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 25%;
        white-space: nowrap;
      }
    }
    &_right {
      width: 100%;
      // height: calc(100% - 48rem);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 6.5rem;
      .item {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        &_title1 {
          width: 24.5rem;
          height: 2rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &_link {
          height: 4.9rem;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.5rem;
          img {
            height: 100%;
            margin: 0 0.95rem;
            cursor: pointer;
          }
        }
        &_title2 {
          width: 12rem;
          height: 1.85rem;
          margin-top: 2.3rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &_share {
          width: 100%;
          height: auto;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 1.75rem;
          .tip {
            height: 1.25rem;
            line-height: 1.25;
            font-size: 1.25rem;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;

            background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .share {
            width: 100%;
            height: 3.75rem;
            display: flex;
            margin-top: 1.85rem;
            justify-content: space-between;
            align-items: center;
            &_link {
              width: calc(100% - 14rem);
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              span {
                height: 1.7rem;
                font-size: 1.7rem;
                line-height: 1.7rem;

                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                width: 65%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            &_btn {
              width: 10rem;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              span {
                height: 1.7rem;
                line-height: 1.7rem;
                font-size: 1.7rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
        &_title3 {
          width: 22.5rem;
          height: 1.85rem;
          margin-top: 2.6rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
        &_reward {
          width: 90%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 2.1rem;
          // padding-left: 0.7rem;
          box-sizing: border-box;
          img {
            width: 100%;
          }
          .reward_list {
            width: calc(100% - 0rem);
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 1.7rem;
            .card {
              width: calc((100% - 4rem) / 3);
              height: auto;
              position: relative;
              &_img {
                width: 100%;
                height: 15rem;
                position: relative;
                .title {
                  height: 1.8rem;
                  white-space: nowrap;
                  font-size: 1.8rem;
                  font-family: SourceHanSerifSC;
                  font-weight: 800;
                  color: #3f2c18;
                  line-height: 1.8rem;
                  position: absolute;
                  top: 1rem;
                  left: 50%;
                  transform: translate(-50%, 0);
                }
              }
              &_title {
                display: block;
                font-size: 1.5rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #ffffff;
                line-height: 1.5rem;
                height: 1.5rem;
                background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-top: 1.5rem;
                margin-bottom: 0.45rem;
              }
              &_text {
                display: block;
                font-size: 1.2rem;
                height: 1.2rem;
                margin-bottom: 0.3rem;
                line-height: 1.2rem;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: rgba(194, 165, 101, 1);
              }
              .card_achieve {
                position: absolute;
                width: 5rem;
                height: 5rem;
                right: -1.8rem;
                top: -1.8rem;
              }
              .invite-effect {
                height: 100%;
                position: absolute;
                left: 50%;
                top: 0%;
                transform: translate(-50%, -0%) scale(2, 1.4);
              }
            }
          }
        }
      }
      .btn {
        width: 90%;
        height: 5.3rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 4rem;
        margin-top: 3rem;
        box-sizing: border-box;
        img {
          // width: 15rem;
          height: 100%;
        }
      }
    }
  }
}
</style>
  