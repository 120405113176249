<template>
  <div
    class="arrow"
    :style="{
      bottom: bottomDistance,
    }"
    v-if="!curActiveSlideIndex"
  >
    <span class="arrow_text">スクロール</span>
    <div
      class="arrow_bg"
      :style="{
        'background-image': 'url(' + imgInfo.arrow_img + ')',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      }"
    ></div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "component_name",
  props: {
    bottomDistance: {
      type: String,
      default: "0rem",
    },
    showShortLine: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },

  created() {},
  computed: {
    ...mapState([
      "msg",
      "title",
      "region",
      "showIndirection",
      "curActiveSlideIndex",
    ]),
    imgInfo() {
      const imgObj = {
        JA: {
          arrow_img: require("@/assets/pc/home/home_arrow.png"),
        },
      };
      return imgObj[this.region];
    },
  },
  watch: {
    showIndirection: {
      handler(val) {
        this.show = val;
      },
      immediate: true,
    },
  },
  mounted() {},

  methods: {},

  destroyed() {},
};
</script>

<style lang="scss" scoped>
@keyframes floating {
  0% {
    transform: translateY(0rem);
  }

  50% {
    transform: translateY(-1rem);
  }

  100% {
    transform: translateY(0rem);
  }
}
.arrow {
  width: 40.38rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  margin-left: -20.19rem;
  animation: floating 1.5s infinite ease-in-out;

  z-index: 999;
  &_text {
    height: 1rem;
    font-size: 1.22rem;
    font-family: SourceHanSerifSC;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 1rem;
  }
  &_bg {
    width: 3rem;
    height: 3rem;
  }
}
</style>
