<template>
  <div
    class="character"
    :style="{
      'background-image': 'url(' + imgInfo.info_list[cur_act_index].bg + ')',
      'background-size': '100%',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    }"
  >
    <div class="character_top">
      <img
        class="l_arrow"
        @click="handelChangeType"
        src="@/assets/mobile/character/title2_button1.png"
        alt=""
        :style="{ top: cur_act_type_index ? '-0.5rem' : '0.3rem' }"
      />
      <div
        class="c_img bs_contain"
        :style="{
          'background-image':
            'url(' + imgInfo.switch_type_imgs[cur_act_type_index] + ')',
        }"
      ></div>
      <img
        class="r_arrow"
        src="@/assets/mobile/character/title_button2.png"
        alt=""
        @click="handelChangeType"
        :style="{ bottom: cur_act_type_index ? '0.6rem' : '0.5rem' }"
      />
    </div>
    <div class="character_bottom">
      <div
        class="arrow bs_contain"
        :style="{
          'background-image':
            'url(' + require('@/assets/mobile/character/button.png') + ')',
        }"
        @click="handelViewCharacter('prev')"
      ></div>
      <div class="head-area">
        <div
          class="head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[0].head_def + ')',
          }"
          @click="handelViewCharacter('prev')"
        ></div>

        <div
          class="head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[1].head_hover + ')',
          }"
        ></div>
        <div
          class="head bs_contain"
          :style="{
            'background-image': 'url(' + currentShowHead[2].head_def + ')',
          }"
          @click="handelViewCharacter('next')"
        ></div>
      </div>

      <div
        class="arrow bs_contain"
        :style="{
          'background-image':
            'url(' + require('@/assets/mobile/character/button2.png') + ')',
        }"
        @click="handelViewCharacter('next')"
      ></div>
    </div>

    <div class="character_center">
      <div class="title">
        <span class="title_text">
          {{ imgInfo.info_list[cur_act_index].name }}
        </span>
        <img
          :src="
            cur_act_index < 6
              ? require('@/assets/mobile/character/icon.png')
              : require('@/assets/mobile/character/icon2.png')
          "
          alt=""
        />
      </div>
      <span class="skill"> {{ imgInfo.info_list[cur_act_index].tag }} </span>
      <div class="type">
        <div class="type_left">
          <img src="@/assets/mobile/character/bp_title.png" alt="" />
          <span> {{ imgInfo.info_list[cur_act_index].level }} </span>
        </div>
        <div class="type_right">
          <img src="@/assets/mobile/character/bp_title2.png" alt="" />
          <span> {{ imgInfo.info_list[cur_act_index].type }} </span>
        </div>
      </div>
      <div class="intro">
        <span>
          {{ imgInfo.info_list[cur_act_index].intro }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { trackClick } from "@/tools/trackClick.js";
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";

export default {
  components: {},
  name: "component_name",
  data() {
    return {
      cur_act_type_index: 0, // 类型0：异兽，1：巨兽
      cur_act_index: 0,
    };
  },
  created() {},
  computed: {},
  computed: {
    ...mapState(["msg", "title", "region", "swiperEnable"]),
    imgInfo() {
      const imgObj = {
        JA: {
          info_list: !this.cur_act_type_index
            ? [
                // ------异兽
                {
                  name: this.$t("role11.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_lion.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_lion.png"),
                  bg: require("@/assets/mobile/character/character1.png"),
                  level: this.$t("role11.level"),
                  type: this.$t("role11.type"),
                  intro: this.$t("role11.intro"),
                  tag: this.countTagText(this.$t("role11.tag")),
                },
                {
                  name: this.$t("role9.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_xblyhu.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_xblyhu.png"),
                  bg: require("@/assets/mobile/character/character2.png"),
                  level: this.$t("role9.level"),
                  type: this.$t("role9.type"),
                  intro: this.$t("role9.intro"),
                  tag: this.countTagText(this.$t("role9.tag")),
                },
                {
                  name: this.$t("role10.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_baihu.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_baihu.png"),
                  bg: require("@/assets/mobile/character/character3.png"),
                  level: this.$t("role10.level"),
                  type: this.$t("role10.type"),
                  intro: this.$t("role10.intro"),
                  tag: this.countTagText(this.$t("role10.tag")),
                },
                {
                  name: this.$t("role8.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_lanhou.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_lanhou.png"),
                  bg: require("@/assets/mobile/character/character4.png"),
                  level: this.$t("role8.level"),
                  type: this.$t("role8.type"),
                  intro: this.$t("role8.intro"),
                  tag: this.countTagText(this.$t("role8.tag")),
                },
                {
                  name: this.$t("role7.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_xiaoxiongmao.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_xiaoxiongmao.png"),
                  bg: require("@/assets/mobile/character/character5.png"),
                  level: this.$t("role7.level"),
                  type: this.$t("role7.type"),
                  intro: this.$t("role7.intro"),
                  tag: this.countTagText(this.$t("role7.tag")),
                },
                {
                  name: this.$t("role6.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_yema.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_yema.png"),
                  bg: require("@/assets/mobile/character/character6.png"),
                  level: this.$t("role6.level"),
                  type: this.$t("role6.type"),
                  intro: this.$t("role6.intro"),
                  tag: this.countTagText(this.$t("role6.tag")),
                },
              ]
            : [
                // ------巨兽
                {
                  name: this.$t("role5.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_bawanglong.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_bwl.png"),
                  bg: require("@/assets/mobile/character/character7.png"),
                  level: this.$t("role5.level"),
                  type: this.$t("role5.type"),
                  intro: this.$t("role5.intro"),
                  tag: this.countTagText(this.$t("role5.tag")),
                },
                {
                  name: this.$t("role4.name"),
                  head_def: require("@/assets/mobile/character/icon_normal_jialong.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_jialong.png"),
                  bg: require("@/assets/mobile/character/character8.png"),
                  level: this.$t("role4.level"),
                  type: this.$t("role4.type"),
                  intro: this.$t("role4.intro"),
                  tag: this.countTagText(this.$t("role4.tag")),
                },
                {
                  name: this.$t("role3.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_jilong.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_jilong.png"),
                  bg: require("@/assets/mobile/character/character9.png"),
                  level: this.$t("role3.level"),
                  type: this.$t("role3.type"),
                  intro: this.$t("role3.intro"),
                  tag: this.countTagText(this.$t("role3.tag")),
                },
                {
                  name: this.$t("role2.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_niulong.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_niulong.png"),
                  bg: require("@/assets/mobile/character/character10.png"),
                  level: this.$t("role2.level"),
                  type: this.$t("role2.type"),
                  intro: this.$t("role2.intro"),
                  tag: this.countTagText(this.$t("role2.tag")),
                },
                {
                  name: this.$t("role1.name"),
                  head_def: require("@/assets/mobile/character/icon_unselected_wanlong.png"),
                  head_hover: require("@/assets/mobile/character/icon_highlight_wanlong.png"),
                  bg: require("@/assets/mobile/character/character11.png"),
                  level: this.$t("role1.level"),
                  type: this.$t("role1.type"),
                  intro: this.$t("role1.intro"),
                  tag: this.countTagText(this.$t("role1.tag")),
                },
              ],
          switch_type_imgs: [
            require("@/assets/mobile/character/title1.png"),
            require("@/assets/mobile/character/title2.png"),
          ],
        },
      };
      return imgObj[this.region];
    },
    currentShowHead() {
      let list = cloneDeep(this.imgInfo.info_list);

      return this.getItemsAroundIndex(list, this.cur_act_index);
    },
  },
  mounted() {},

  methods: {
    handelChangeType() {
      this.cur_act_index = 0;
      this.cur_act_type_index = (this.cur_act_type_index + 1) % 2;
    },
    countTagText(v) {
      return v.join("/");
    },
    getItemsAroundIndex(array, index) {
      const arrayLength = array.length;

      // 确保数组不为空且索引在数组范围内
      if (arrayLength === 0) return [];
      index = ((index % arrayLength) + arrayLength) % arrayLength; // 处理负索引

      const prevIndex = (index - 1 + arrayLength) % arrayLength;
      const nextIndex = (index + 1) % arrayLength;

      return [array[prevIndex], array[index], array[nextIndex]];
    },
    handelViewCharacter(t) {
      if (t === "next") {
        if (this.cur_act_index !== this.imgInfo.info_list.length - 1) {
          this.cur_act_index++;
        } else {
          this.cur_act_index = 0;
        }
      } else if (t === "prev") {
        if (this.cur_act_index !== 0) {
          this.cur_act_index--;
        } else {
          this.cur_act_index = this.imgInfo.info_list.length - 1;
        }
      }
    },
  },

  destroyed() {},
};
</script>

<style lang="scss" scoped>
.character {
  width: 100%;
  height: 145rem;
  position: relative;
  margin-top: 15rem;
  z-index: 99;
  &_top {
    // width: 17rem;
    height: 7rem;
    position: absolute;
    top: 1rem;
    left: 2.5rem;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    .c_img {
      width: 30rem;
      height: 8rem;
    }
    img {
      // width: 1.45rem;
      height: 4.5rem;
      cursor: pointer;
      margin-bottom: 0.5rem;
      &:hover {
      }
    }
  }
  &_bottom {
    width: calc(100% - 4.5rem);
    height: 19.5rem;
    position: absolute;
    bottom: 17%;
    left: 2.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .arrow {
      width: 8.2rem;
      height: 100%;
      cursor: pointer;
    }
    .head-area {
      width: calc(100% - 12rem);
      height: 16.5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      .head {
        width: 16.5rem;
        height: 16.5rem;
        cursor: pointer;
      }
    }
  }

  &_center {
    width: calc(100% - 4.5rem);
    // height: auto;
    height: 26rem;
    position: absolute;
    top: 45.85%;
    right: 2.25rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .title {
      width: 100%;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      &_text {
        height: 3.5rem;
        font-size: 3.5rem;
        line-height: 3.5rem;
        font-family: SourceHanSerifSC;
        font-weight: bold;
        color: #ffffff;

        background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      img {
        height: 4rem;
        margin-left: 1.25rem;
      }
    }
    .skill {
      font-size: 1.5rem;
      font-family: SourceHanSerifSC;
      font-weight: 500;
      color: #ffffff;

      background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: 1rem 0;
      white-space: nowrap;
    }
    .type {
      width: 100%;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &_left {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img {
          height: 100%;
        }
        span {
          font-size: 1.5rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #192521;

          background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 1rem;
        }
      }
      &_right {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 7rem;
        img {
          height: 100%;
        }
        span {
          font-size: 1.5rem;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #192521;

          background: linear-gradient(0deg, #e0cfa5 0%, #fef4dc 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-left: 1rem;
          white-space: nowrap;
        }
      }
    }
    .intro {
      width: 100%;
      height: auto;

      padding: 1.2rem 2.25rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-image: url("~@/assets/mobile/character/bp.png");
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;
      margin-top: 2rem;
      position: relative;
      box-sizing: border-box;
      &::before {
        width: 100%;
        height: 0.6rem;
        position: absolute;
        top: -0.3rem;
        left: 0;
        content: "";

        background-image: url("~@/assets/mobile/character/line.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      &::after {
        width: 100%;
        height: 0.6rem;
        content: "";
        position: absolute;
        bottom: -0.3rem;
        left: 0;
        background-image: url("~@/assets/mobile/character/line.png");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
      }
      span {
        max-height: 12rem;
        overflow-y: auto;
        display: block;
        font-size: 1.6rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 3rem;
        width: 100%;
        text-align: left;
      }
    }
  }
}
</style>
