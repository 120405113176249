var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalVisible)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog bs_100",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
      width: '40rem',
      height: '21rem',
    })},[_c('img',{class:"dialog-close",attrs:{"src":require("@/assets/pc/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.close}}),_c('img',{staticClass:"dialog-title",attrs:{"src":_vm.imgInfo.title_img,"alt":""}}),_c('div',{staticClass:"dialog-content"},[_c('p',{staticClass:"prize"},_vm._l((_vm.imgInfo.prize_text_list[_vm.id]),function(item,index){return _c('span',{key:index},[_vm._v(_vm._s(item))])}),0),_c('div',{staticClass:"rule bs_contain",style:({
          'background-image': 'url(' + _vm.imgInfo.rule_img + ')',
        })},[_c('span',{staticClass:"cdk_code"},[_vm._v(_vm._s(_vm.$t("register.tip1"))+_vm._s(_vm.drawNumber || "-"))]),_c('span',{staticClass:"copy",on:{"click":_vm.copyToClipboard}},[_vm._v(_vm._s(_vm.$t("prize.$3")))])]),_c('div',{staticClass:"area"},[_c('div',{staticClass:"btn bs_contain",style:({
            'background-image': 'url(' + _vm.imgInfo.btn_img + ')',
          }),on:{"click":_vm.close}})])])]),_c('div',{ref:"copyArea"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }