import Vue from "vue";
import App from "./App.vue";
import store from "./store/index";
import { Progress, Tooltip } from "element-ui";
import "./font/font.scss";
import "element-ui/lib/theme-chalk/index.css";
import "./style/common.scss";

import { nanoid } from "nanoid";
import "./components/msgbox/msgbox.css";
import tipsDialogPlugin from "@/components/tipsModal";
import tipsMobileModalPlugin from "@/components/tipsMobileModal";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Input,
  Checkbox,
  Carousel,
  CarouselItem,
  Table,
  TableColumn,
  Loading,
} from "element-ui";
import VueLazyload from "vue-lazyload";
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.use(Progress);
Vue.use(Tooltip);
Vue.use(VueLazyload);
Vue.use(Input);
Vue.use(Checkbox);
Vue.use(tipsDialogPlugin);
Vue.use(tipsMobileModalPlugin);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Loading);

import i18n from "./i18n/i18n";

Vue.config.productionTip = false;
Vue.prototype.$LOGID = nanoid;

const vue = new Vue({
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

export default vue;
