<template>
  <div class="swiper-container">
    <div class="swiper-wrapper">
      <div class="swiper-slide each swiper-no-swiping">
        <Home
          @changeRightNav="changeRightNav"
          @hiddenBottomDirIcon="hiddenBottomDirIcon"
        />
      </div>
      <div class="swiper-slide each swiper-no-swiping"><Register /></div>
      <div class="swiper-slide each swiper-no-swiping"><Reach /></div>
      <div class="swiper-slide each swiper-no-swiping"><Prize /></div>
      <div class="swiper-slide each swiper-no-swiping">
        <Character />
      </div>
      <div class="swiper-slide each swiper-no-swiping"><Carousel /></div>
    </div>
    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <BottomIndirection
      v-if="activeInfo.activeSlideIndex !== 3 && showBottomIndirection"
      bottomDistance="0.5rem"
      :showShortLine="false"
    />
  </div>
</template>
<script>
import "swiper/swiper-bundle.css";
import Swiper from "swiper/bundle";
import Home from "./PC/Home.vue";
import Register from "./PC/Register.vue";
import Character from "./PC/Character.vue";
import Reach from "./PC/Reach.vue";
import Prize from "./PC/Prize.vue";
import Carousel from "./PC/Carousel.vue";
// import { aesDecrypt } from "@/setting/aesUtil.js";
import BottomIndirection from "@/components/BottomIndirection";
import { mapMutations, mapState } from "vuex";
export default {
  components: {
    Home,
    Register,
    Reach,
    Prize,
    Character,
    Carousel,
    BottomIndirection,
  },

  provide() {
    return {
      activeSlideObj: this.activeInfo,
    };
  },
  computed: {
    ...mapState(["msg", "title", "swiperEnable", "curActiveSlideIndex"]),
  },
  watch: {
    swiperEnable: {
      handler(val) {
        this.show = val;
        if (this.mySwiper) {
          if (val) {
            this.mySwiper.enable();
          } else {
            this.mySwiper.disable();
          }
        }
      },
    },
    curActiveSlideIndex: {
      handler(val) {
        if (this.mySwiper) {
          this.setSlideIndex(val);
        }
      },
    },
  },
  data() {
    return {
      mySwiper: null,
      activeInfo: {
        activeSlideIndex: 0,
      },
      showTurnTable: true,
      isClickTurnTable: false,
      showBottomIndirection: true,
    };
  },

  mounted() {
    const _this = this;
    this.mySwiper = new Swiper(".swiper-container", {
      speed: 800, // 切换轮播速度
      mousewheel: true, // 支持鼠标滚轮滚动
      direction: "vertical",
      loop: false, // 循环模式选项
      noSwiping: true,
      preventInteractionOnTransition: true,
      longSwipes: true,
      longSwipesRatio: 0.6, // 调整阀值（0~1）
      on: {
        init: function () {
          //Swiper初始化了
          // this.activeIndex = 2;
          _this.activeInfo.activeSlideIndex = this.activeIndex;
        },
        slideChange: function () {
          _this.activeInfo.activeSlideIndex = this.activeIndex;
          _this.setSwiperActiveIndex(this.activeIndex);
          if (_this.isClickTurnTable) {
            return;
          }
        },
      },
    });
  },
  methods: {
    ...mapMutations(["openNewMsg", "setSwiperStatus", "setSwiperActiveIndex"]),
    setSlideIndex(i) {
      this.mySwiper.slideTo(i, 1000, false);
      this.activeInfo.activeSlideIndex = i;
    },
    jumpToHero(i) {
      this.mySwiper.slideTo(1, 1000, false);
      this.activeInfo.activeSlideIndex = 1;
    },
    changeRightNav(v) {
      if (!v) {
        this.mySwiper.disable();
      } else {
        this.mySwiper.enable();
      }
      this.showTurnTable = v;
    },
    hiddenBottomDirIcon(v) {
      this.showBottomIndirection = v;
    },
  },
  beforeDestroy() {
    this.mySwiper = null;
  },
};
</script>
<style lang="scss" scope>
.swiper-container {
  width: 100%;
  height: 100vh !important;
}
.wiper-wrapper {
  width: 100%;
  height: 100vh !important;
}
.each {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &:first-child {
    width: 100%;
    height: 100vh !important;
    // background-color: #fff;
  }
  &:nth-child(2) {
    width: 100%;
    height: 100vh;
    // background-color: #b50a0a;
  }
  &:nth-child(3) {
    width: 100%;
    height: 100vh;
    // background-color: #ae30a6;
  }
  &:nth-child(4) {
    width: 100%;
    height: 100vh;
    // background-color: #1c1c7e;
  }
}
</style>
