<template>
  <div class="footer_cls">
    <div style="margin: 0 2%" :class="{ 'ja-star-logo-cls': true }">
      <img src="@/assets/pc/carousel/bg_footer_logo.png" alt="logo" />
      <div class="ja-right">
        <p>
          <a
            href="https://beastlord-jp-pre.allstarcluster.com/privacy_ja.html"
            style="cursor: pointer"
            target="_blank"
            >プライバシーポリシー</a
          >
          ｜
          <a
            href="https://beastlord-jp-pre.allstarcluster.com/protocol_ja.html"
            style="cursor: pointer"
            target="_blank"
            >利用規約</a
          >
        </p>
        <p>
          <a
            href="https://beastlord-jp-pre.allstarcluster.com/commodity_ja.html"
            style="cursor: pointer"
            target="_blank"
            >特定商取引法に基づく表示</a
          >
          ｜
          <a
            href="https://beastlord-jp-pre.allstarcluster.com/instructions_ja.html"
            style="cursor: pointer"
            target="_blank"
            >資金決済法に基づく表示</a
          >
        </p>
        <p>©️2023.STAR UNION GAME - ALL RIGHTS RESERVED</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>

<style lang="scss" scoped>
.footer_cls {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: #000;
  // padding: 2rem 0 1rem 0;

  min-width: 100%;
  z-index: 1020000;
  height: 7.7vh;
  position: absolute;
  bottom: 0;
  .ja-star-logo-cls {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100.333rem;
    img {
      width: 8.88rem !important;
      height: 100% !important;
      margin-right: 1.5rem;
    }
    div {
      text-align: right;
    }
  }
  .ja-right {
    p {
      margin: 0.1rem 0.133rem 0 0.1333rem;
      text-align: left;
      font-size: 0.84rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      user-select: none;
      a {
        font-size: 0.84rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .logo_cls {
    display: flex;
    align-items: center;
    justify-content: center;

    div {
      text-align: left;
      color: #8a8a8a;
      font-size: 0.933rem;

      p {
        margin: 0.267rem 0.133rem 0 0.1333rem;

        a {
          color: #8a8a8a;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
</style>
