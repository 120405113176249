var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalVisible)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog bs_100",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
      width: '50rem',
      height: '40rem',
    })},[_c('img',{class:"dialog-close",attrs:{"src":require("@/assets/pc/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.close}}),_c('img',{staticClass:"dialog-title",attrs:{"src":_vm.imgInfo.title_img,"alt":""}}),_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"rule bs_contain",style:({
          'background-image': 'url(' + _vm.imgInfo.rule_img + ')',
        })},[_c('span',[_vm._v(_vm._s(_vm.$t("prize.$10")))]),_c('span',[_vm._v(_vm._s(_vm.$t("prize.$11"))+_vm._s(_vm.act_ids.length))])]),_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t("prize.$12")))]),_c('div',{staticClass:"area"},_vm._l((_vm.imgInfo.text_list),function(item,index){return _c('div',{key:index,staticClass:"area_item bs_100",style:({
            'background-image': 'url(' + _vm.imgInfo.item_bg + ')',
          })},[_c('span',[_vm._v(_vm._s(item.text))]),_c('div',{staticClass:"btn bs_100",style:({
              'background-image':
                'url(' +
                (!_vm.act_ids.includes(item.id)
                  ? _vm.imgInfo.btn_act_img
                  : _vm.imgInfo.btn_def_img) +
                ')',
              cursor: !_vm.act_ids.includes(item.id) ? 'pointer' : 'no-drop',
            }),on:{"click":function($event){return _vm.getLottery(item.id)}}})])}),0)])]),_c('div',{ref:"copyArea"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }