var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalVisible)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog bs_100",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
      width: '40rem',
      height: '34rem',
    })},[_c('img',{class:"dialog-close",attrs:{"src":require("@/assets/pc/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.close}}),_c('img',{staticClass:"dialog-title",style:({ marginBottom: _vm.type === 1 ? '0.5rem' : '1.5rem' }),attrs:{"src":_vm.imgInfo.title_img,"alt":""}}),_c('div',{staticClass:"dialog-content"},[_c('div',{staticClass:"rule bs_contain",style:({
          'background-image': 'url(' + _vm.imgInfo.rule_img + ')',
        })},[(_vm.type === 1)?_c('div',{staticClass:"tipsArea"},[_c('span',{staticClass:"cdk_code"},[_vm._v(_vm._s(_vm.$t("register.tip1"))+_vm._s(_vm.drawNumber))]),_c('span',{staticClass:"copy",on:{"click":_vm.copyToClipboard}},[_vm._v(_vm._s(_vm.$t("prize.$3")))])]):_c('span',[_vm._v(_vm._s(_vm.$t("register.tip2")))])]),_c('div',{staticClass:"area",style:({ bottom: _vm.type === 1 ? '0' : '2rem' })},[(_vm.type === 1)?_c('div',{staticClass:"rule bs_contain",style:({
            'background-image': 'url(' + _vm.imgInfo.rule_img + ')',
          })},[_c('span',[_vm._v(_vm._s(_vm.$t("register.tip3")))])]):_vm._e(),(_vm.type === 1)?_c('div',{staticClass:"btn-area"},_vm._l((_vm.imgInfo.link_list),function(item,index){return _c('div',{key:index,staticClass:"item bs_contain",style:({
              'background-image': 'url(' + item.img + ')',
            }),on:{"click":function($event){return _vm.openStorRegister(item.name)}}})}),0):_c('div',{staticClass:"btn bs_contain",style:({
            'background-image': 'url(' + _vm.imgInfo.btn_img + ')',
          }),on:{"click":_vm.close}})])])]),_c('div',{ref:"copyArea"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }