var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })},[_c('div',{staticClass:"content-img",style:({
        'background-image': 'url(' + _vm.imgInfo.intro_img + ')',
        'background-size': 'contain',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      })}),_c('div',{staticClass:"content_info"},[_c('div',{staticClass:"intro-btn"},[_c('img',{staticClass:"head",attrs:{"src":_vm.imgInfo.head_img,"alt":""}}),_c('div',{staticClass:"download"},_vm._l((_vm.imgInfo.link_list),function(item,img_index){return _c('img',{key:img_index,staticClass:"download_btn",attrs:{"src":item.img,"alt":""},on:{"click":function($event){return _vm.openLink(item.name)}}})}),0),_c('img',{staticClass:"play",attrs:{"src":_vm.imgInfo.paly_bg,"alt":""},on:{"click":_vm.openVideoModal}})])])]),_c('transition',{attrs:{"name":"el-fade-in-linear"}},[(_vm.showVideoModal)?_c('div',{staticClass:"video"},[_c('div',{staticClass:"video-center"},[_c('img',{staticClass:"close_img",attrs:{"src":require("@/assets/mobile/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.closeVideoModal}}),_c('video',{ref:"video",attrs:{"src":_vm.countLanguageVideo,"controls":""}})])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }