var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",style:({
    'background-image': 'url(' + _vm.imgInfo.bg + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
  })},[_c('div',{staticClass:"content",style:({
      'background-image': 'url(' + _vm.imgInfo.info_list[_vm.cur_act_index].bg + ')',
      'background-size': 'cover',
      'background-repeat': 'no-repeat',
      'background-position': _vm.cur_act_type_index
        ? 'center center'
        : 'center bottom',
    })},[_c('div',{staticClass:"content_left"},[_c('div',{staticClass:"top_arrow bs_contain",style:({
          'background-image':
            'url(' + require('@/assets/pc/character/bg_button1.png') + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('prev')}}}),_c('div',{staticClass:"top_head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[0].head_def + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('prev')}}}),_c('div',{staticClass:"center_line bs_contain",style:({
          'background-image':
            'url(' +
            require('@/assets/pc/character/bg_xuanxiangbian.png') +
            ')',
        })}),_c('div',{staticClass:"center_head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[1].head_hover + ')',
        })}),_c('div',{staticClass:"bot_arrow bs_contain",style:({
          'background-image':
            'url(' + require('@/assets/pc/character/bg_button2.png') + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('next')}}}),_c('div',{staticClass:"bot_head bs_contain",style:({
          'background-image': 'url(' + _vm.currentShowHead[2].head_def + ')',
        }),on:{"click":function($event){return _vm.handelViewCharacter('next')}}})]),_c('div',{staticClass:"content_bottom"},[_c('img',{staticClass:"l_arrow",style:({ top: _vm.cur_act_type_index ? '5%' : '10%' }),attrs:{"src":require("@/assets/pc/character/bg_arrow1.png"),"alt":""},on:{"click":_vm.handelChangeType}}),_c('div',{staticClass:"c_img bs_contain",style:({
          'background-image':
            'url(' + _vm.imgInfo.switch_type_imgs[_vm.cur_act_type_index] + ')',
        })}),_c('img',{staticClass:"r_arrow",style:({ bottom: _vm.cur_act_type_index ? '15%' : '10%' }),attrs:{"src":require("@/assets/pc/character/bg_arrow1_2.png"),"alt":""},on:{"click":_vm.handelChangeType}})]),_c('div',{staticClass:"content_right"},[_c('div',{staticClass:"title"},[_c('span',{staticClass:"title_text"},[_vm._v(_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].name))]),_c('img',{attrs:{"src":_vm.cur_act_index < 5
              ? require('@/assets/pc/character/bp_icon2.png')
              : require('@/assets/pc/character/bp_icon1.png'),"alt":""}})]),_c('span',{staticClass:"skill"},[_vm._v(_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].tag))]),_c('div',{staticClass:"type"},[_c('div',{staticClass:"type_left"},[_c('img',{attrs:{"src":require("@/assets/pc/character/bp_title_xiyoudu.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].level))])]),_c('div',{staticClass:"type_right"},[_c('img',{attrs:{"src":require("@/assets/pc/character/bp_title_leixing.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].type)+" ")])])]),_c('div',{staticClass:"intro"},[_c('span',[_vm._v(" "+_vm._s(_vm.imgInfo.info_list[_vm.cur_act_index].intro)+" ")])])]),_vm._m(0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content_shadow"},[_c('img',{attrs:{"src":require("@/assets/pc/character/bg_yinying.png"),"alt":""}})])}]

export { render, staticRenderFns }