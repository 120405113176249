import request from "./request";

/** 上报埋点数据 */
export function reportData(data, isFile) {
  return request({
    url: "/report/origin_file",
    method: "post",
    data,
    isFile,
    type: "open-gateway",
  });
}

/** 通过ip获取国家code */
export function reqIpInfo(data) {
  return request({
    url: "/api/common/addr",
    method: "post",
    data,
  });
}

/** 获取ip */
export function reqCurrentIp(data) {
  return request({
    url: "/common/addr",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 邮箱预约 */
export function reqReservation(data) {
  return request({
    url: "/operationsite/reservation/hand_reservation",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 商店预约 */
export function reqStoreReservation(data) {
  return request({
    url: "/operationsite/reservation/click_store",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 更新当前可抽奖次数*/
export function reqCanLotteryNum(data) {
  return request({
    url: "/operationsite/reservation/get_can_lottery_num",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 抽奖*/
export function reqHandelLottery(data) {
  return request({
    url: "/operationsite/reservation/hand_lottery",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 抽奖次数来源*/
export function reqLotterySource(data) {
  return request({
    url: "/operationsite/reservation/get_lottery_num_source",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 中奖记录*/
export function reqLotteryWinning(data) {
  return request({
    url: "/operationsite/reservation/list_lottery_winning",
    method: "post",
    data,
    type: "open-gateway",
  });
}

/** 领取要请假奖励*/
export function reqReceivePrize(data) {
  return request({
    url: "/operationsite/reservation/receive_invited_prize",
    method: "post",
    data,
    type: "open-gateway",
  });
}
