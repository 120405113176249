var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"content bs_cover",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
    })},[_c('div',{staticClass:"content_title bs_cover"},[_c('img',{attrs:{"src":_vm.imgInfo.title_img,"alt":""}})]),_c('div',{staticClass:"video-container"},[_c('el-carousel',{ref:"carousel",staticClass:"your-carousel",attrs:{"indicator-position":"none","interval":4000,"type":"card","autoplay":false,"arrow":"never"},on:{"change":_vm.carouselChange},model:{value:(_vm.activeIndex),callback:function ($$v) {_vm.activeIndex=$$v},expression:"activeIndex"}},_vm._l((_vm.imgInfo.info_list),function(item,i){return _c('el-carousel-item',{key:i},[_c('div',{style:({
              'background-image':
                'url(' +
                (i === _vm.activeIndex ? item.head_hover : item.head_def) +
                ')',
            })})])}),1),_c('div',{staticClass:"button-prev",on:{"click":function($event){return _vm.changeActiveIndex('prev')}}}),_c('div',{staticClass:"button-next",on:{"click":function($event){return _vm.changeActiveIndex('next')}}}),_c('div',{staticClass:"pagination"},_vm._l((5),function(item,i){return _c('span',{key:i,class:i === _vm.activeIndex ? 'active-slide' : 'inactive-slide',on:{"click":function($event){return _vm.setCurCarousel(i)}}})}),0)],1)]),_c('TheFooter')],1)}
var staticRenderFns = []

export { render, staticRenderFns }