var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.curActiveSlideIndex)?_c('div',{staticClass:"arrow",style:({
    bottom: _vm.bottomDistance,
  })},[_c('span',{staticClass:"arrow_text"},[_vm._v("スクロール")]),_c('div',{staticClass:"arrow_bg",style:({
      'background-image': 'url(' + _vm.imgInfo.arrow_img + ')',
      'background-size': 'contain',
      'background-repeat': 'no-repeat',
      'background-position': 'center center',
    })})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }