<template>
  <div class="register">
    <div class="content">
      <div
        class="content_center"
        :style="{
          'background-image': 'url(' + imgInfo.content_bg + ')',
          'background-size': '100% 100%',
          'background-repeat': 'no-repeat',
          'background-position': 'center top',
        }"
      >
        <img class="title" :src="imgInfo.title_bg" alt="" />
        <i class="el-icon-close" @click="setRegisterVisibleStatus(false)"></i>

        <img
          class="q_mark"
          src="@/assets/pc/register/bp_title_punct.png"
          alt=""
          @click="showTipsModal"
        />
        <div class="area">
          <span class="area_tip">{{ $t("register.title1") }}</span>
          <el-input
            class="area_input"
            v-model="email"
            :placeholder="$t('register.tip')"
          ></el-input>
          <div class="area_check">
            <el-checkbox v-model="choice">
              <span>{{ $t("register.agree") }}</span>
            </el-checkbox>
          </div>
          <div class="area_agree">
            <span @click="viewAgreement(1)">{{ $t("register.agree1") }}</span>
            <span @click="viewAgreement(2)"> {{ $t("register.agree2") }}</span>
          </div>

          <div
            class="area_btn"
            :style="{
              'background-image':
                'url(' +
                (isViewPrizeStatus ? imgInfo.btn_done_img : imgInfo.btn_img) +
                ')',
            }"
            @click="handelRegister"
          ></div>
          <div class="area_award">
            <div
              class="item bs_contain"
              v-for="(item, index) in imgInfo.award_list"
              :key="index"
              :style="{
                'background-image': 'url(' + item + ')',
              }"
            ></div>
          </div>
          <div class="area_download">
            <img
              v-for="(item, index) in imgInfo.link_list"
              :key="index"
              :src="item.img"
              alt=""
              @click="openLink(item.name)"
            />
          </div>
          <span class="area_tips">
            {{ $t("register.tip4") }}
          </span>
        </div>
      </div>
    </div>
    <registerSuccessModal
      :visible="registerSuccessInfo.visible"
      :type="registerSuccessInfo.type"
      :cdk_code="registerSuccessInfo.cdk_code"
      @closeModal="closeRegisTipsModal"
      @openLink="openLink"
    />
  </div>
</template>
  
  <script>
import { debounce } from "@/tools/debounce.js";
import { trackClick } from "@/tools/trackClick";
import { convertUTCTimeToLocalTime } from "@/tools/convertUTCTimeToLocalTime";
import { mapMutations, mapState } from "vuex";
import registerSuccessModal from "./components/registerSuccessModal.vue";
import { reqReservation, reqStoreReservation } from "@/settings/api.js";

export default {
  name: "component_name",
  components: {
    registerSuccessModal,
  },
  data() {
    return {
      activePeopleNum: 0,
      email: "",
      showDesModal: false,
      choice: false,
      disabled: false,
      registerSuccessInfo: {
        visible: false,
        cdk_code: "",
        type: 1, //1  事前登录   2商店注册
      },
    };
  },
  created() {},
  computed: {
    ...mapState(["msg", "title", "region", "region_type", "user_info"]),

    imgInfo() {
      const imgObj = {
        JA: {
          content_bg: require("@/assets/mobile/register/bp.png"),
          title_bg: require("@/assets/mobile/register/title.png"),
          btn_img: require("@/assets/mobile/register/anniupre.png"),
          btn_done_img: require("@/assets/mobile/register/anniu_normal.png"),
          award_list: [
            require("@/assets/mobile/register/gift1.png"),
            require("@/assets/mobile/register/gift3.png"),
            require("@/assets/mobile/register/gift2.png"),
          ],
          link_list: [
            {
              name: "google",
              img: require("@/assets/mobile/register/bp_download_googleplay.png"),
            },
            {
              name: "ios",
              img: require("@/assets/mobile/register/bp_download_appstore.png"),
            },
          ],
        },
      };
      return imgObj[this.region];
    },
    // 是否是查看奖励的状态
    isViewPrizeStatus() {
      let { email, identity_id } = this.user_info;
      if (identity_id && this.email === email) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  watch: {
    user_info: {
      handler(val) {
        if (val.email) {
          this.email = val.email;
          this.choice = true;
          // this.disabled = true;
        } else {
          this.email = "";
          this.choice = false;
          // this.disabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["openNewMsg", "setUserInfo", "refreshCanLotteryNum"]),
    closeRegisTipsModal() {
      this.registerSuccessInfo = {
        visible: false,
        cdk_code: "",
        type: 1, //1  事前登录   2商店注册
      };
    },
    handelRegister: debounce(function () {
      return;
      const _this = this;
      const title = _this.$t("msg.title");
      if (!this.choice) {
        this.openNewMsg({
          title,
          body: "まずプロトコルをチェックしてください",
          autoClose: true,
          time: 1000,
        });
      } else {
        let reg = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!reg.test(this.email)) {
          this.openNewMsg({
            title,
            body: "メールフォーマットエラー",
            autoClose: true,
            time: 1000,
          });
        } else {
          if (this.isViewPrizeStatus) {
            // this.registerSuccessInfo = {
            //   visible: true,
            //   cdk_code: localStorage.getItem("email_cdk_code"),
            //   type: 1,
            // };
            return;
          }
          trackClick({
            behavior_tag: "Submit Email",
          });
          reqReservation({
            app_id: +process.env.VUE_APP_PROJECT_ID,
            device: this.user_info?.device,
            // device: Math.random().toString(),
            ip: this.user_info?.ip,
            email: this.email,
            invited_id: this.user_info?.invite_id || undefined,
            region_type: this.region_type,
          }).then((res) => {
            if (res.code === 20000 || res.code === 20001) {
              let { can_lottery_num, cdk_code, identity_id } = res.data;
              _this.setUserInfo({ can_lottery_num });
              _this.setUserInfo({ identity_id });
              _this.setUserInfo({ email: _this.email });
              this.refreshCanLotteryNum();
              // if (cdk_code) {
              //   localStorage.setItem("email_cdk_code", cdk_code);
              //   this.registerSuccessInfo = {
              //     visible: true,
              //     cdk_code,
              //     type: 1,
              //   };
              // }
            } else {
              this.openNewMsg({
                title: this.$t("msg.title"),
                body: this.$t(`errMsg.$${res.code}`),
                autoClose: true,
                time: 1000,
              });
            }
          });
        }
      }
    }, 600),
    jumpLink(way) {
      let urlObj = {
        ios: {
          link: "https://apps.apple.com/app/id6475638006",
          track_name: "Click Ios store",
        },
        google: {
          link: "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          track_name: "Click Google store",
        },
        twitter: {
          link: "https://twitter.com/NoDgameJP",
          track_name: "Click Twitter",
        },
        // wait
        youtube: {
          link: "https://www.youtube.com/@NoDgames",
          track_name: "Click Youtube",
        },
        // wait
        line: {
          link: "https://lin.ee/J9fiO9G",
          track_name: "Click Line",
        },
      };
      let url = urlObj[way].link;
      window.open(url, "_blank");
      // window.open(url, "_blank");
      trackClick({
        behavior_tag: urlObj[way].track_name,
      });
    },
    openLink(way) {
      if (way === "ios") {
        trackClick({
          behavior_tag: "Click Ios store",
        });
        window.open("https://apps.apple.com/app/id6475638006", "_blank");
      } else {
        trackClick({
          behavior_tag: "Click Google store",
        });
        window.open(
          "https://play.google.com/store/apps/details?id=com.allstarunion.beastlord.jp",
          "_blank"
        );
      }
    },
    showTipsModal() {
      this.$tipMobileModal({
        type: "type4",
        onClose: () => {},
      });
    },
    viewAgreement(type) {
      switch (type) {
        case 1:
          window.open(
            "https://beastlord-jp-pre.allstarcluster.com/privacy_ja.html",
            "_blank"
          );
          break;
        case 2:
          window.open(
            "https://beastlord-jp-pre.allstarcluster.com/protocol_ja.html",
            "_blank"
          );
          break;
        default:
          break;
      }
    },
  },

  destroyed() {},
};
</script>
  
  <style lang="scss" scoped>
.register {
  width: 100%;
  height: 75rem;
  margin-top: 8rem;
  position: relative;
  // overflow: hidden;
  // position: absolute;
  // top: 0;

  .content {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99;
    left: 50%;
    top: 0%;
    transform: translate(-50%, 0);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &_center {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      .title {
        position: absolute;
        top: -2.5rem;
        left: 4rem;
        width: 20.44rem;
      }
      .q_mark {
        position: absolute;
        top: -0.6rem;
        left: 23rem;
        height: 2rem;
        cursor: pointer;
      }

      .area {
        width: calc(100% - 15rem);
        height: auto;
        position: absolute;
        left: 50%;
        top: 11.7rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transform: translate(-48%, 0);

        &_tip {
          font-size: 3.65rem;
          height: 3.65rem;
          line-height: 3.65rem;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          background: linear-gradient(
            180deg,
            #ffffff 0%,
            #decc9e 99.365234375%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          margin-top: 0.7rem;
        }
        &_input {
          margin-top: 4.12rem;
          ::v-deep {
            .el-input__inner {
              width: 40rem;
              height: 4rem;
              line-height: 4rem;
              border: 1px solid;
              border-image: linear-gradient(0deg, #d3c583, #d9d09d) 10 10;
              background: linear-gradient(0deg, #5d858f 0%, #274d54 100%);
              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #ffffff;
              text-align: center;
              &::placeholder {
                width: 100%;
                text-align: center;
                font-size: 1.36rem;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
              }
            }
          }
        }
        &_check {
          width: 100%;
          height: 1.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2.65rem;

          ::v-deep {
            .el-checkbox {
              display: flex;
              align-items: center;
            }
            .el-checkbox__inner {
              width: 1.5rem;
              height: 1.5rem;
              border: 1px solid #d3c583;
              background-color: transparent;
              &::after {
                height: 5px !important;
                left: 2px !important;
                top: 0px !important;
              }
            }
            .el-checkbox__label {
              font-size: 1.4rem;
              font-family: Source Han Sans CN;
              font-weight: 300;
              color: #ffffff;
              padding: 0;
              padding-left: 0.65rem;
              height: 2rem;
              line-height: 2rem;
              font-weight: normal;
              span {
                height: 1.4rem;
                line-height: 1.4rem;
              }
            }
          }
        }
        &_agree {
          width: 100%;
          height: 1.4rem;
          line-height: 1.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 0.8rem;
          span {
            font-size: 1.4rem;
            height: 1.4rem;
            ine-height: 1.4rem;
            font-family: Source Han Sans CN;
            color: #ffffff;
            text-decoration: underline;
            &:last-child {
              margin-left: 1.85rem;
            }
          }
        }
        &_btn {
          width: 16.8rem;
          height: 5.44rem;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: center center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2.4rem;
          &:hover {
            transform: scale(1.05);
            cursor: pointer;
          }
        }
        &_award {
          width: 100%;
          height: 13.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 1.5rem;
          .item {
            width: 15.5rem;
            height: 100%;
          }
        }
        &_download {
          width: 40rem;
          height: 5.4rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 2.25rem;
          img {
            width: calc((100% - 3.35rem) / 2);
            height: 100%;
            &:hover {
              cursor: pointer;
              transform: scale(1.05);
            }
          }
        }
        &_tips {
          width: auto;
          height: 1.35rem;
          margin-top: 2.1rem;
          font-family: Source Han Sans CN;
          font-weight: 300;
          font-size: 1.36rem;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }
}
</style>
  