var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reach"},[_c('div',{staticClass:"reach-area"},[_c('img',{staticClass:"title",attrs:{"src":_vm.imgInfo.title_img,"alt":""}}),_c('div',{staticClass:"people",style:({
        'background-image': 'url(' + _vm.imgInfo.reach_bg + ')',
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
      })},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.addCommas(_vm.$t('reward.peopleNum')))}}),_c('img',{attrs:{"src":require("@/assets/mobile/reach/punct.png"),"alt":""},on:{"click":_vm.showTipsModal}})]),_c('div',{staticClass:"area"},_vm._l((_vm.imgInfo.reward_list),function(item,index){return _c('div',{key:index,staticClass:"area_item",style:({
          'background-image': 'url(' + item.img + ')',
          'background-size': '100% 100%',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        })},[(item.reach)?_c('div',{staticClass:"tag",class:{ end_tag: index === 4 },style:({
            'background-image': 'url(' + _vm.imgInfo.reach_img + ')',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
            'background-position': 'center center',
          })}):_vm._e()])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }