<template>
  <div
    class="contain"
    :style="{ overflowY: forbidMobileScroll ? 'hidden' : 'auto' }"
    ref="contain"
  >
    <div
      class="contain-center bs_100"
      :style="{
        'background-image':
          'url(' + require('@/assets/mobile/chartlet/bg.png') + ')',
      }"
    >
      <img
        class="contain-center-left_bg"
        src="@/assets/mobile/chartlet/bg_ pic2.png"
        alt=""
      />

      <img
        class="contain-center-center_bg"
        src="@/assets/mobile/chartlet/bg_ pic3.png"
        alt=""
      />
      <img
        class="contain-center-center2_bg"
        src="@/assets/mobile/chartlet/bg_ pic4.png"
        alt=""
      />
      <Home />
      <register ref="register" />
      <Reach />
      <Prize @handleScrollToRegister="handleScrollToRegister" />
      <Character />
      <Carousel />
      <FooterMobile />
      <img class="f_bg" src="@/assets/mobile/chartlet/bg_pic5.png" alt="" />
    </div>
  </div>
</template>
<script>
import Home from "./Mobile/Home.vue";
import TheHeader from "@/components/TheHeader";
import FooterMobile from "@/components/FooterMobile";
import register from "./Mobile/register.vue";
import Reach from "./Mobile/Reach.vue";
import Prize from "./Mobile/Prize.vue";
import Character from "./Mobile/Character.vue";
import Carousel from "./Mobile/Carousel.vue";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    TheHeader,
    Home,
    FooterMobile,
    register,
    Reach,
    Prize,
    Character,
    Carousel,
  },

  provide() {
    return {
      activeSlideObj: this.activeInfo,
    };
  },
  data() {
    return {
      mySwiper: null,
      activeInfo: {
        activeSlideIndex: 0,
      },
      showHeader: true,
    };
  },
  computed: {
    ...mapState(["forbidMobileScroll", "region"]),
  },
  watch: {
    forbidMobileScroll: {
      handler(val) {},
      immediate: true,
    },
  },
  mounted() {
    const _this = this;
  },

  methods: {
    ...mapMutations(["openNewMsg", "setSwiperStatus", "setSwiperActiveIndex"]),
    jumpToHero(i) {
      this.mySwiper.slideTo(1, 1000, false);
      this.activeInfo.activeSlideIndex = 1;
    },
    hideHeader(v) {
      this.showHeader = v;
    },
    handleScrollToRegister() {
      this.$nextTick(() => {
        this.$refs.register.$el.scrollIntoView({
          behavior: "smooth", // 或者 'auto'
          block: "center", // 或者 'center', 'end', 'nearest'
        });
      });
    },
  },
  beforeDestroy() {
    this.mySwiper = null;
  },
};
</script>
<style lang="scss" scope>
.contain {
  width: 100%;
  height: 100%;
  // box-sizing: border-box;
  position: relative;
  overflow-y: auto;

  &-center {
    width: 100%;
    height: auto;
    padding-bottom: 20rem;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    &-left_bg {
      width: 22rem;
      height: auto;
      position: absolute;
      top: 83rem;
      left: 0;
      z-index: 99;
    }
    &-center_bg {
      width: 100%;
      height: auto;
      position: absolute;
      top: 179rem;
      left: 0;
      z-index: 99;
    }
    &-center2_bg {
      width: 30%;
      height: auto;
      position: absolute;
      top: 299rem;
      left: 0;
      z-index: 99;
    }

    .f_bg {
      width: 100%;
      height: auto;
      position: absolute;
      bottom: 0;
      z-index: 99;
      left: 0;
    }
  }
}
</style>
