var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home",style:({
    'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
    'background-size': 'cover',
    'background-repeat': 'no-repeat',
    'background-position': 'center center',
  })},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"content_title bs_contain",style:({
        'background-image': 'url(' + _vm.imgInfo.title_img + ')',
      })},[_c('img',{attrs:{"src":_vm.imgInfo.ques_img,"alt":""},on:{"click":_vm.showTipsModal}})]),_c('div',{staticClass:"content-area bs_100",style:({
        'background-image': 'url(' + _vm.imgInfo.area_bg + ')',
      })},[_c('div',{staticClass:"content-area_left"},[_c('div',{staticClass:"lottery-container"},_vm._l((_vm.imgInfo.prize_list),function(item,index){return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(index === 4 && _vm.lotteryLoading),expression:"index === 4 && lotteryLoading"}],key:index,class:[
              'lottery-grid',
              'bs_contain',
              { highlight: _vm.currentIndex === index },
              { start: index === 4 } ],style:({
              'background-image':
                index !== 4
                  ? 'url(' + item.prize_img + ')'
                  : 'url(' + item.start_img + ')',
              cursor:
                index === 4
                  ? _vm.lotteryLoading || _vm.isRunning
                    ? 'no-drop'
                    : 'pointer'
                  : 'default',
            }),on:{"click":function($event){return _vm.startLottery(index)}}},[(index == 4)?_c('div',{staticClass:"lottery-button"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(_vm.currentIndex === index)?_c('lotteryEffect',{staticClass:"lottery-effect"}):_vm._e()],1)}),0)]),_c('div',{staticClass:"content-area_right"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_title1 bs_cover",style:({
              'background-image': 'url(' + _vm.imgInfo.link_title_bg + ')',
            })}),_c('div',{staticClass:"item_link"},_vm._l((_vm.imgInfo.link_list),function(item,index){return _c('img',{key:index,attrs:{"src":item.img,"alt":""},on:{"click":function($event){return _vm.jumpLink(item)}}})}),0)]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_title2 bs_cover",style:({
              'background-image': 'url(' + _vm.imgInfo.share_title_bg + ')',
            })}),_c('div',{staticClass:"item_share"},[_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t("prize.$1")))]),_c('div',{staticClass:"share"},[_c('div',{staticClass:"share_link bs_100",style:({
                  'background-image': 'url(' + _vm.imgInfo.share_link_bg + ')',
                })},[_c('span',[_vm._v(_vm._s(_vm.current_share_link ? _vm.current_share_link : _vm.$t("prize.$2")))])]),_c('div',{ref:"copyButton",staticClass:"share_btn bs_contain",style:({
                  'background-image': 'url(' + _vm.imgInfo.share_btn_bg + ')',
                }),on:{"click":_vm.copyToClipboard}},[_c('span',[_vm._v(_vm._s(_vm.$t("prize.$3")))])])])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item_title3 bs_cover",style:({
              'background-image': 'url(' + _vm.imgInfo.reward_title_bg + ')',
            })}),_c('div',{staticClass:"item_reward"},[_c('img',{attrs:{"src":require("@/assets/pc/prize/bp_line.png"),"alt":""}}),_c('div',{staticClass:"reward_list"},_vm._l((_vm.imgInfo.reward_list),function(item,index){return _c('div',{key:index,staticClass:"card bs_cover",style:({
                  'background-image':
                    'url(' +
                    (_vm.currentPrizeCanDraw(item.id) ? item.bg : item.bg_done) +
                    ')',
                  cursor:
                    _vm.currentPrizeCanDraw(item.id) &&
                    _vm.lottery_source_share_length >= item.id
                      ? 'pointer'
                      : 'no-drop',
                }),on:{"click":function($event){return _vm.handelDrawInvitePrize(item.id)}}},[(_vm.lottery_source_share_length >= item.id)?_c('img',{staticClass:"card_achieve",attrs:{"src":_vm.currentPrizeCanDraw(item.id)
                      ? _vm.imgInfo.share_achieve
                      : _vm.imgInfo.share_not_achieve,"alt":""}}):_vm._e(),(
                    _vm.currentPrizeCanDraw(item.id) &&
                    _vm.lottery_source_share_length >= item.id
                  )?_c('InvitePrizeEffect',{staticClass:"invite-effect"}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"btn"},_vm._l((_vm.imgInfo.btn_list),function(item,index){return _c('img',{key:index,attrs:{"src":item,"alt":""},on:{"click":function($event){return _vm.showModal(index)}}})}),0)])])]),_c('prizeRule',{attrs:{"visible":_vm.prizeRuleVisible},on:{"closeModal":function($event){_vm.prizeRuleVisible = false},"handelGetLottery":_vm.handelGetLottery,"handelTwitterGetLottery":_vm.handelTwitterGetLottery}}),_c('prizeRecordModal',{attrs:{"visible":_vm.prizeRecordVisible},on:{"closeModal":function($event){_vm.prizeRecordVisible = false}}}),_c('drawInvitePrizeModal',{attrs:{"visible":_vm.drawInvitePrizeInfo.visible,"cdk_code":_vm.drawInvitePrizeInfo.cdk_code,"id":_vm.drawInvitePrizeInfo.id},on:{"closeModal":_vm.closeDrawInvitePrizeModal}}),_c('registerSuccessModal',{attrs:{"visible":_vm.registerSuccessInfo.visible,"type":_vm.registerSuccessInfo.type,"cdk_code":_vm.registerSuccessInfo.cdk_code},on:{"closeModal":_vm.closeRegisTipsModal,"openLink":_vm.openLink}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }