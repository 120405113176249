import webStorage from "./setTimeStorage";
import vue from "../main";
import { reportData } from "../settings/api";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { reqIpInfo } from "../settings/api";
// 获取设备id
function getDeviceId() {
  return new Promise((resolve, reject) => {
    FingerprintJS.load().then((fp) => {
      fp.get().then((result) => {
        const visitorId = result.visitorId;
        localStorage.setItem("dv", visitorId);
        resolve();
      });
    });
  });
}
// 获取国家编码
function getCountryInfo(ip) {
  return new Promise((resolve, reject) => {
    reqIpInfo({ ip_list: [ip] })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        resolve(err);
      });
  });
}
export async function trackClick(obj) {
  const type = Object.prototype.toString.call(obj);

  var clientIP;
  try {
    clientIP = window.ip || null;
  } catch {
    clientIP = null;
  }

  if (clientIP) {
    localStorage.setItem("ip", clientIP);
    if (!localStorage.getItem("gj")) {
      let countryCode = await getCountryInfo(clientIP);
      if (
        countryCode.code === 20000 &&
        countryCode.data.addr_list.length !== 0 &&
        countryCode.data.addr_list[0] !== null
      ) {
        localStorage.setItem("gj", countryCode.data.addr_list[0].iso_code);
      }
    }
  }

  if (type === "[object Object]") {
    let formatObj = {
      ...{
        log_id: vue.$LOGID(32), //唯一id
        media_source: localStorage.getItem("media_source") || "",
        email: vue.$store.state.user_info.email || "",
        relation_info: vue.$store.state.user_info.invite_id || "",
        event_name: "log_website_appointment", //事件名
        created_at: parseInt(new Date().getTime() / 1000), // 当前时间戳（s）
        zone: `+${0 - new Date().getTimezoneOffset() / 60}`, //时区
        device_id: vue.$store.state.user_info.device || "", // 设备id
        role_id: vue.$store.state.user_info.identity_id || "", // 身份id
        game_id: +process.env.VUE_APP_PROJECT_ID, //release:64,master:17
        terminal: vue.$store.state.deviceType, // 设备类型，pc 还是  mobile,
        year: new Date().getFullYear().toString(), // 年
        month: (new Date().getMonth() + 1).toString(), //月份
        day: new Date().getDate().toString(), // 日
        country: localStorage.getItem("gj") || "", // 国家
        ip: localStorage.getItem("ip") || "", // ip
      },
      ...obj,
    };
    let arr = [];
    if (webStorage.getItem("localStorage", "tc")) {
      let v = webStorage.getItem("localStorage", "tc");
      if (v.constructor === Object) {
        arr = JSON.parse(v.trackClick);
      } else {
        arr = v;
      }
      arr.push(formatObj);
      let blob = new Blob([JSON.stringify(arr)], { type: "application/json" });
      let size = blob.size;
      // 大小限制
      if (size > 1024 * 1024) {
        reportData(
          { file: blob, stream_prefix: process.env.VUE_APP_STREAM_PREFIX },
          true
        ).then((res) => {
          if (res.code === 20000) {
            localStorage.removeItem("tc");
          } else {
          }
        });
      }
    } else {
      arr = [formatObj];
    }
    webStorage.setItem("localStorage", "tc", JSON.stringify(arr), 300000);
  } else {
  }
}
