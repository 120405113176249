var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.modalVisible)?_c('div',{staticClass:"dialog-overlay"},[_c('div',{staticClass:"dialog bs_100",style:({
      'background-image': 'url(' + _vm.imgInfo.bg_img + ')',
      width: '66rem',
      height: '37rem',
    })},[_c('img',{class:"dialog-close",attrs:{"src":require("@/assets/pc/popup/popup_closebutton.png"),"alt":""},on:{"click":_vm.close}}),_c('img',{staticClass:"dialog-title",attrs:{"src":_vm.imgInfo.title_img,"alt":""}}),_c('div',{staticClass:"dialog-content"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.queryLoading),expression:"queryLoading"}],staticClass:"mainTable",staticStyle:{"width":"100%,height: 50%"},attrs:{"data":_vm.tableData,"border":"","height":"calc(100% - 10rem)"}},[_c('el-table-column',{attrs:{"prop":"id","align":"center","label":_vm.$t('prize.$4'),"width":"180"}}),_c('el-table-column',{attrs:{"prop":"name","align":"center","label":_vm.$t('prize.$5'),"width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.name || "-")+" ")]}}],null,false,3517570837)}),_c('el-table-column',{attrs:{"prop":"create_at","align":"center","label":_vm.$t('prize.$6')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_vm._v(" "+_vm._s(row.create_at ? _vm.formatTimestampToJST(row.create_at) : "-")+" ")]}}],null,false,622866089)}),_c('el-table-column',{attrs:{"prop":"cdk_code","align":"center","label":_vm.$t('prize.$7')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var row = ref.row;
return [_c('span',{ref:"n_text",staticClass:"n_text"},[_vm._v(_vm._s(row.cdk_code))]),_c('span',{staticClass:"n_link",on:{"click":function($event){return _vm.copyToClipboard(row.cdk_code)}}},[_vm._v(_vm._s(_vm.$t("prize.$3")))])]}}],null,false,1299947433)})],1),_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t("prize.$8")))]),_c('span',{staticClass:"tip"},[_vm._v(_vm._s(_vm.$t("prize.$9")))]),_c('div',{staticClass:"btn"},_vm._l((_vm.imgInfo.btn_img_list),function(item,index){return _c('img',{key:index,attrs:{"src":_vm.hover_index === index ? item.act : item.def,"alt":""},on:{"mouseenter":function($event){_vm.hover_index = index},"mouseleave":function($event){_vm.hover_index = -1},"click":function($event){return _vm.handelConfirm(index)}}})}),0)],1)]),_c('div',{ref:"copyArea"})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }