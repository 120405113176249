<template>
  <div class="dialog-overlay" v-if="modalVisible">
    <div
      class="dialog bs_100"
      :style="{
        'background-image': 'url(' + imgInfo.bg_img + ')',
      }"
    >
      <img
        :class="`dialog-close`"
        src="@/assets/pc/popup/popup_closebutton.png"
        alt=""
        @click="close"
      />
      <img class="dialog-title" :src="imgInfo.title_img" alt="" />
      <div class="dialog-content">
        <div
          class="rule bs_contain"
          :style="{
            'background-image': 'url(' + imgInfo.rule_img + ')',
          }"
        >
          <span>{{ $t("prize.$10") }}</span>
          <span>{{ $t("prize.$11") }}{{ act_ids.length }}</span>
        </div>
        <span class="tip">{{ $t("prize.$12") }}</span>
        <div class="area">
          <div
            class="area_item bs_100"
            :style="{
              'background-image': 'url(' + imgInfo.item_bg + ')',
            }"
            v-for="(item, index) in imgInfo.text_list"
            :key="index"
          >
            <span>{{ item.text }}</span>
            <div
              class="btn bs_100"
              :style="{
                'background-image':
                  'url(' +
                  (!act_ids.includes(item.id)
                    ? imgInfo.btn_act_img
                    : imgInfo.btn_def_img) +
                  ')',
              }"
              @click="getLottery(item.id)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div ref="copyArea"></div>
  </div>
</template>
      
      <script>
import { mapMutations, mapState } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import ClipboardJS from "clipboard";
import { reqLotterySource } from "@/settings/api.js";

export default {
  props: {
    visible: false,
  },
  data() {
    return {
      modalVisible: false,
      act_ids: [],
    };
  },
  watch: {
    visible: {
      handler(val) {
        this.modalVisible = val;
      },
      immediate: true,
    },
    lottery_source: {
      handler(val) {
        this.act_ids = [];
        if (val.includes(1)) {
          this.act_ids.push(0);
        }
        if (val.includes(2)) {
          this.act_ids.push(1);
        }
        if (val.includes(3)) {
          let arr = val.filter((item) => {
            return item === 3;
          });
          arr.forEach((each, index) => {
            this.act_ids.push(index + 2);
          });
        }
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    ...mapState([
      "msg",
      "region",
      "swiperEnable",
      "region_type",
      "user_info",
      "lottery_source",
    ]),

    imgInfo() {
      const imgObj = {
        JA: {
          title_img: require("@/assets/mobile/popup/popup_title4.png"),
          bg_img: require("@/assets/mobile/popup/popup_bg5.png"),
          rule_img: require("@/assets/mobile/popup/popup_edit.png"),
          item_bg: require("@/assets/mobile/popup/popup_edit2.png"),
          btn_act_img: require("@/assets/mobile/popup/popup_button4_normal.png"),
          btn_def_img: require("@/assets/mobile/popup/popup_button5_unselected.png"),
          text_list: [
            {
              text: "メールを入力して予約を完了する",
              id: 0,
            },
            {
              text: "に移動して公式アカウントをフォローする",
              id: 1,
            },
            {
              text: "累計1人の友達が予約を完了する",
              id: 2,
            },
            {
              text: "累計2人の友達が予約を完了する",
              id: 3,
            },
            {
              text: "累計3人の友達が予約を完了する",
              id: 4,
            },
          ],
        },
      };
      return imgObj["JA"];
    },
    transformContent() {
      return (text) => {
        return text.split("\n");
      };
    },
  },
  mounted() {},
  methods: {
    ...mapMutations(["openNewMsg", "setSwiperStatus", "setSwiperActiveIndex"]),
    open() {
      this.modalVisible = true;
    },
    close() {
      this.modalVisible = false;
      this.$emit("closeModal", false);
    },

    handelConfirm() {
      switch (this.type) {
        case "type1":
          this.close();
          break;

        default:
          break;
      }
    },
    getLottery(id) {
      if (!this.act_ids.includes(id)) {
        this.close();
        if (id === 1) {
          this.$emit("handelTwitterGetLottery");
        } else {
          this.$emit("handelGetLottery", id);
        }
      }
    },
  },
};
</script>
  <style>
.el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
  background-color: #566567 !important;
}
</style>
  <style lang="scss" scoped>
@keyframes open_mask {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
/* 添加对话框的基本样式 */
.dialog-overlay {
  /* 样式省略，覆盖整个屏幕的半透明背景 */
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.58);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  animation: open_mask 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.dialog {
  /* 对话框样式 */
  width: 81%;
  height: 35rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2.6rem;
  position: absolute;
  left: 50%;
  top: 50%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  &-close {
    width: 2.65rem;
    height: 2.65rem;
    position: absolute;
    top: 1.75rem;
    right: 1.75rem;
    cursor: pointer;
  }

  &-title {
    width: auto;
    height: 5.5rem;
    margin-bottom: 0.5rem;
    margin-top: -1.5rem;
  }
  &-content {
    width: 100%;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .rule {
      width: 38.28rem;
      height: 2.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        height: 1rem;
        line-height: 1rem;
        font-size: 1rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        &:last-child {
          margin-left: 2rem;
        }
      }
    }
    .tip {
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: left;
      font-size: 0.8rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8b9a9c;
      margin-top: 1.3rem;
    }
    .area {
      width: 36rem;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 1.8rem;
      &_item {
        width: 100%;
        height: 2.9rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.6rem 0 1.5rem;
        margin-bottom: 1rem;
        span {
          line-height: 1.1rem;
          height: 1.1rem;
          font-size: 1.1rem;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
        }
        .btn {
          width: 8rem;
          height: 2.2rem;
        }
      }
    }
  }
}
</style>