// 防抖
export function debounce(fn, delay = 500) {
  if (typeof fn !== 'function') return
  let time = null
  return function (...args) {
    clearTimeout(time)
    time = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}
