<template>
  <div class="footer">
    <div class="footer-content">
      <img
        src="@/assets/mobile/carousel/xhlogo.png"
        :class="countImgSize"
        alt="LOGO"
      />
      <div
        class="footer-tips"
        :style="{
          fontSize: '1.1rem',
        }"
      >
        <div class="footer-tips-owner">©2023 Star Union All right reserved</div>

        <div>
          <div :style="{ margin: '0 0 0px' }">
            <a
              href="https://static-sites.allstarunion.com/service/general_protocol_ja.html"
              class="link"
              target="_blank"
              >利用規約</a
            >
            <span :style="{ margin: '0px 1rem' }"> | </span>
            <a
              href="https://static-sites.allstarunion.com/service/general_privacy_ja.html"
              class="link"
              target="_blank"
              >プライバシーポリシー</a
            >
          </div>
          <!-- <div>
            <a
              href="https://static-sites.allstarunion.com/service/general_instructions_ja.html"
              class="link"
              target="_blank"
              >資金決済法に基づく表示</a
            >
            <span :style="{ margin: '0px 2px' }"> | </span>
            <a
              href="https://static-sites.allstarunion.com/service/general_commodity_ja.html"
              class="link"
              target="_blank"
              >特定商取引法に基づく表示</a
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      protocolAddress: {
        //隐私协议
        privacy: {
          ZH: "https://static-sites.allstarunion.com/service/general_privacy_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_privacy_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_privacy_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_privacy_ko.html",
        },
        // 服务条款
        service: {
          ZH: "https://static-sites.allstarunion.com/service/general_protocol_cn.html",
          TW: "https://static-sites.allstarunion.com/service/general_protocol_tw.html",
          EN: "https://static-sites.allstarunion.com/service/general_protocol_en.html",
          KO: "https://static-sites.allstarunion.com/service/general_protocol_ko.html",
        },
      },
    };
  },
  computed: {
    ...mapState(["mobile", "language"]),
    /**
     * @description: 移动端对log图片缩小处理
     * @param {}
     * @return 返回类名
     */
    countImgSize() {
      return "mobile-img";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  height: 9.78rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(11, 9, 0, 0.58);
  position: absolute;
  bottom: 0;
  z-index: 999999;
  left: 0;
  padding: 2.2rem auto 1.11rem;
  &-content {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-img {
    width: 14.22rem;
    height: 6.44rem;
  }
  &-tips {
    // width: 136px;
    height: 6.44rem;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: #fff;
    margin-left: 2rem;
    white-space: nowrap;
    margin-top: 1rem;
    .link {
      font-size: 1.7rem;
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    &-owner {
      font-size: 1.7rem;
      // color: #fff;
      // margin-bottom: 5px;
    }
  }
}
</style>
